import * as React from 'react';
import { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Step, StepLabel, Stepper, Typography, WithTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { WithTranslation } from 'react-i18next';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { ApiRequest, Headset, TopicNotification } from '../api/Api';
import IconWarning from '@material-ui/icons/Warning';

import RestartIcon from '@material-ui/icons/Refresh';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import DismissIcon from '@material-ui/icons/PhonelinkErase';
import HeadsetCommands from '../misc/HeadsetCommands';

const HeadsetPicture = require('../img/headsetPicture.png');
const TabletPicture = require('../img/tabletPicture.png');

interface HeadsetPanelProps extends WithTranslation, WithTheme {
  serialNumber: string;
  trainingKey: string;
  trainingSessionKey: string;
  wsManager: WsConnectionManager;
}

interface HeadsetPanelState {
  headset?: Headset;
  properlyEnrolled: boolean;
  showConfirmExitTraining: boolean;
}

export default class HeadsetPanel extends Component<HeadsetPanelProps, HeadsetPanelState> {

  private topicRegistrationId: number;

  constructor(props: HeadsetPanelProps) {
    super(props);
    this.state = {
      properlyEnrolled: false,
      showConfirmExitTraining: false
    };
  }

  componentDidMount(): void {
    //register
    this.loadHeadsetData();
  }

  componentWillUnmount(): void {
    if (this.topicRegistrationId != null) {
      this.props.wsManager.unregister(this.topicRegistrationId);
    }
  }

  private loadHeadsetData() {
    let request: Partial<ApiRequest> = {
      action: 'db-headset-get',
      parameters: {
        serialNumber: this.props.serialNumber
      }
    };

    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve headset data', response);
      } else {
        this.setState({headset: response.result as Headset}, () => {
          if (this.topicRegistrationId == null) {
            this.props.wsManager.registerForTopic('headsets',
              (registrationId) => this.topicRegistrationId = registrationId,
              notification => this.notificationReceived(notification));
          }
        });
      }
    });
  }

  private notificationReceived(notification: TopicNotification) {
    //console.log('Notification:', notification);
    if (notification.details['serialNumber'] === this.props.serialNumber) {
      this.loadHeadsetData();
    }
  }

  private sendReset() {
    HeadsetCommands.resetOne(
      this.props.wsManager,
      response => {
        if (response['status'] !== 'ok') {
          console.error('Could not reset headset', response);
        }
      },
      this.props.serialNumber);
  }

  private sendPause() {
    HeadsetCommands.pauseOne(
      this.props.wsManager,
      response => {
        if (response['status'] !== 'ok') {
          console.error('Could not pause headset', response);
        }
        this.setState({})
      },
      this.props.serialNumber);
  }

  private sendResume() {
    HeadsetCommands.resumeOne(
      this.props.wsManager,
      response => {
        if (response['status'] !== 'ok') {
          console.error('Could not resume headset', response);
        }
      },
      this.props.serialNumber);
  }

  private sendEnrollmentRequest() {
    HeadsetCommands.enrollOne(this.props.wsManager,
      response => {
        if (response['status'] !== 'ok') {
          console.error('Could not enroll headset', response);
        }
      }
      , this.props.serialNumber, this.props.trainingKey, this.props.trainingSessionKey);
  }

  private releaseFromSession() {
    HeadsetCommands.dismissOne(
      this.props.wsManager,
      response => {
        if (response['status'] !== 'ok') {
          console.error('Could not dismiss headset', response);
        }
      },
      this.props.serialNumber, this.props.trainingSessionKey
    );
  }

  render() {

    if (this.state.headset == null) {
      return <Paper style={{padding: '20px', minWidth: '250px', maxWidth: '350px', minHeight: '100px'}}>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <IconWarning/>
          </Grid>
          <Grid item>
            <Typography>No data</Typography>
          </Grid>
        </Grid>
      </Paper>;
    }
    let pauseButton;
    if (this.state.headset.status === 'PAUSED') {
      pauseButton =
        <Button fullWidth={true} variant='outlined' color='primary' onClick={() => this.sendResume()} style={{display: 'flex'}}>
          <PlayIcon/>
          <span style={{
            flexGrow : 1,
            textAlign: 'center'
          }}>{this.props.t('HeadsetPanel.resumeSession')}</span>
        </Button>;
    } else {
      pauseButton =
        <Button fullWidth={true} disabled={this.state.headset.status !== 'TRAINING'} variant='outlined' color='primary' onClick={() => this.sendPause()} style={{display: 'flex'}}>
          <PauseIcon/>
          <span style={{
            flexGrow : 1,
            textAlign: 'center'
          }}>{this.props.t('HeadsetPanel.pauseSession')}</span>
        </Button>;
    }

    return <Paper style={{
      padding  : '20px',
      minWidth : '250px',
      maxWidth : '350px',
      minHeight: '100px',
      position : 'relative'
    }} id={'panel_' + this.props.serialNumber}>
      <Dialog
        open={this.state.headset.status !== 'DISCONNECTED' && this.state.headset.currentTrainingSessionKey !== this.props.trainingSessionKey}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        container={() => document.getElementById('panel_' + this.props.serialNumber)}
        style={{position: 'absolute', zIndex: 0}}
        BackdropProps={{style: {position: 'absolute'}}}
      >
        <Grid container direction='column' spacing={2} style={{padding: '10px'}} alignItems='center'>
          <Grid item>
            <Typography align='center' variant={'h2'} style={{
              marginBottom: '15px',
              marginTop   : '15px'
            }}>{this.props.t('HeadsetPanel.NotEnrolled.header')}</Typography>
            <Typography align='center' variant={'body1'}>{this.props.t('HeadsetPanel.NotEnrolled.description')}</Typography>
          </Grid>
          <Grid item>
            <Button fullWidth={true} variant='contained' color='primary' onClick={() => this.sendEnrollmentRequest()}>{this.props.t('Actions.add')}</Button>
          </Grid>
          <Grid item>
            <Button fullWidth={true} variant='contained' color='secondary' onClick={() => this.releaseFromSession()}>{this.props.t('Actions.disconnect')}</Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={this.state.headset.status === 'DISCONNECTED'}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        container={() => document.getElementById('panel_' + this.props.serialNumber)}
        style={{position: 'absolute', zIndex: 0}}
        BackdropProps={{style: {position: 'absolute'}}}
      >
        <Grid container direction='column' spacing={2} style={{padding: '10px'}} alignItems='center'>
          <Grid item>
            <Typography align='center' variant={'h2'} style={{
              marginBottom: '15px',
              marginTop   : '15px'
            }}>{this.props.t('HeadsetPanel.Disconnected.header')}</Typography>
          </Grid>
          <Grid item>
            <IconWarning/>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        key='dialog'
        open={this.state.showConfirmExitTraining}
        onClose={() => this.setState({showConfirmExitTraining: false})}
        container={() => document.getElementById('panel_' + this.props.serialNumber)}
        style={{position: 'absolute', zIndex: 0}}
        BackdropProps={{style: {position: 'absolute'}}}
      >
        <DialogTitle>{this.props.t('HeadsetPanel.ConfirmExit.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.t('HeadsetPanel.ConfirmExit.text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({showConfirmExitTraining: false})} color="primary">
            {this.props.t('Actions.cancel')}
          </Button>
          <Button onClick={() => this.releaseFromSession()} color="secondary" variant='contained' autoFocus>
            {this.props.t('Actions.disconnect')}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container direction='column'>
        <Grid item container direction='column' alignItems='center' spacing={3}>
          <Grid item>
            <Typography variant='h2'>{this.state.headset.commonName}</Typography>
          </Grid>
          <Grid item>
            <img src={(this.state.headset.kind === 'headset' ? HeadsetPicture : TabletPicture)} style={{width: '150px'}}/>
          </Grid>
        </Grid>
        <Grid item>
          <table style={{marginTop: '18px', marginBottom: '18px'}}>
            <tbody>
            <tr>
              <td><Typography variant='h6'>{this.props.t('HeadsetPanel.serialNumber')}</Typography></td>
              <td><Typography variant='h5'>{this.state.headset.serialNumber}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant='h6'>{this.props.t('HeadsetPanel.status')}</Typography></td>
              <td><Typography variant='h5'>{this.state.headset.status}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant='h6'>{this.props.t('HeadsetPanel.currentModule')}</Typography></td>
              <td><Typography variant='h5'>{this.state.headset.currentModuleKey}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant='h6'>{this.props.t('HeadsetPanel.headsetBatteryLevel')}</Typography></td>
              <td><Typography variant='h5'>{this.state.headset.headsetBatteryLevel}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant='h6'>{this.props.t('HeadsetPanel.controllerBatteryLevel')}</Typography></td>
              <td><Typography variant='h5'>{this.state.headset.controllerBatteryLevel}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant='h6'>{this.props.t('HeadsetPanel.lastModified')}</Typography></td>
              <td><Typography variant='h5'>{new Date(this.state.headset.lastModified).toLocaleString()}</Typography>
              </td>
            </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item container direction='column' spacing={1}>
          <Grid item>
            {pauseButton}
          </Grid>
          <Grid item>
            <Button fullWidth={true} variant='outlined' color='secondary' onClick={() => this.sendReset()} style={{display: 'flex'}}>
              <RestartIcon/>
              <span style={{
                flexGrow : 1,
                textAlign: 'center'
              }}>{this.props.t('HeadsetPanel.resetHeadset')}</span>
            </Button>
          </Grid>
          <Grid item>
            <Button fullWidth={true} variant='outlined' color='secondary' onClick={() => this.setState({showConfirmExitTraining: true})} style={{display: 'flex'}}>
              <DismissIcon/>
              <span style={{
                flexGrow : 1,
                textAlign: 'center'
              }}>{this.props.t('HeadsetPanel.dismissHeadset')}</span>
            </Button>
          </Grid>
        </Grid>
        {/*
          <Grid item>
            <Stepper activeStep={1} alternativeLabel>
              <Step>
                <StepLabel>Module 1</StepLabel>
              </Step>
              <Step>
                <StepLabel>Module 2</StepLabel>
              </Step>
              <Step>
                <StepLabel>Module 3</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          */}
      </Grid>
    </Paper>;
  }
}