import * as React from 'react';
import { Component, CSSProperties } from 'react';
import { Theme, Typography, withTheme } from '@material-ui/core';
import Version from '../misc/Version';

interface FooterProps {
  theme: Theme;
}

interface FooterState {
}

class Footer extends Component<FooterProps, FooterState> {

  private footerStyle = {
    background   : this.props.theme.palette.background.paper,
    borderTop    : '1px solid #bfbfbf',
    paddingTop   : '20px',
    paddingBottom: '20px',
    left         : 0,
    bottom       : 0,
    width        : '100vw',
    textAlign: 'center'
  } as Partial<CSSProperties>;

  constructor(props: FooterProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <div style={this.footerStyle}>
      <Typography align='center' variant='caption' display='inline'>
        © Copyright 2017-2020   |   VR Training by <a href="http://virtual-rangers.com" target="_blank"><Typography align='center' variant='h6' style={{display: 'inline-block'}}>Virtual Rangers</Typography></a>   |   All Rights Reserved   |   v.{Version.version}
      </Typography>
    </div>;
  }
}

export default withTheme(Footer);