import * as React from 'react';
import { Component } from 'react';
import { AppBar, Color, Dialog, Grid, Typography, withTheme, WithTheme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import UserMenu from '../cmp/UserMenu';
import * as Keycloak from 'keycloak-js';
import { KeycloakInstance } from 'keycloak-js';
import Footer from '../cmp/Footer';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { AppConfig, Language, Training } from '../api/Api';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HomePage from './HomePage';
import TrainingPage from './TrainingPage';
import Button from '@material-ui/core/Button';
import SecurityIcon from '@material-ui/icons/Security';
import AccessDeniedIcon from '@material-ui/icons/Block';
import ConnectingIcon from '@material-ui/icons/RssFeed';
import { WithTranslation, withTranslation } from 'react-i18next';

import i18n from 'i18next';

const VRLogo = require('../img/Logo-VR-Training-h70.png');
const VRLogoPlain = require('../img/Logo-VR-Training-etiquette-300h-400x200.png');

const FR_LANG_ICON = require('../img/flag_FR.png');
const DE_LANG_ICON = require('../img/flag_DE.png');
const EN_LANG_ICON = require('../img/flag_EN.png');
const LU_LANG_ICON = require('../img/flag_LU.png');


const defaultTraining = {
  '_id'         : {'$oid': '5d56601aaa2bc7d299b35564'},
  'description' : {
    'fr': 'La formation de <b>Vigilance Médicale</b> est une série de modules de formation sur les soins infirmiers.',
    'en': 'The <b>Medical Vigilance</b> training is a suite of modules on nursing activities.',
    'de': ''
  },
  'key'         : 'TRAINING_HRS_1',
  'lastModified': {'$date': 1565942388628},
  'locked'      : false,
  'name'        : {'fr': 'Vigilance Médicale', 'en': 'Medical vigilance', 'de': ''}
};


interface LandingPageProps extends WithTheme, WithTranslation {
}

interface LandingPageState {
  wsManager?: WsConnectionManager;
  keycloak?: KeycloakInstance;
  connectionStatus: 'init' | 'denied' | 'connectingWs' | 'connected' | 'disconnected';
  stage: 'home' | 'training';
  lang: Language;
  selectedTraining?: Training;
  appConfig?: AppConfig;
}

class LandingPage extends Component<LandingPageProps, LandingPageState> {

  /*
    private static keycloakConf = {
      'realm'                : 'HopitauxSchuman',
      'url'                  : location.origin+'/auth',
      'ssl-required'         : 'external',
      'clientId'             : 'vr-webapp-front',
      'verify-token-audience': true,
      'credentials'          : {
        'secret': 'f05c9c83-95a8-4298-a546-6509af34c58b'
      },
      'confidential-port'    : 0,
      'policy-enforcer'      : {}
    };
  */

  private keycloakConf: any;

  constructor(props: LandingPageProps) {
    super(props);
    this.state = {
      stage           : 'home', //TODO reset to home
      lang            : 'fr',
      connectionStatus: 'init',
      //selectedTraining: defaultTraining
    };
  }

  componentDidMount() {

    window.onbeforeunload = () => {
      if (this.state.wsManager != null) {
        this.state.wsManager.disconnect();
      }
    };

    console.debug('Loading Keycloak configuration');
    fetch('ir/kclonf.json').then(response => {
      console.debug('Received response', response);
      response.json().then(content => {
        console.debug(content);
        this.keycloakConf = content;
        this.authenticateUser(success => {
          if (success) {
            this.loadUserInfos((gotInfo) => {
              this.connectWebSocket();
            });
          } else {
            this.setState({
              connectionStatus: 'denied'
            });
          }
        });
      }).catch(error => {
        console.error(error);
      });
    }).catch(error => {
      console.error(error);
    });


  }

  private authenticateUser(done: (success: boolean) => void) {
    let keycloak = Keycloak(this.keycloakConf);
    keycloak.init({
      onLoad: 'login-required'
    }).success((success) => {
      console.debug('User authenticated');
      this.setState({
        keycloak: keycloak
      }, () => done(success));
    }).error(error => {
      console.error(error);
      done(false);
    });
  }

  private loadUserInfos(done: (gotInfo: boolean) => void) {
    //console.log('User authenticated.  Loading user infos');
    this.state.keycloak.loadUserInfo().success(() => {
      //console.log('UserInfo', this.state.keycloak.userInfo);
      //console.log('Loading user profile');
      this.state.keycloak.loadUserProfile().success(() => {
        //console.log('User profile', this.state.keycloak.profile);
        // console.log('Checking roles', this.state.keycloak.realmAccess.roles, this.state.keycloak.hasRealmRole('trainer'), this.state.keycloak.hasResourceRole('trainer', 'vr-webapp-front'));
        if (this.state.keycloak.userInfo.hasOwnProperty('locale')) {
          this.setState({
            lang: (this.state.keycloak.userInfo as any)['locale']
          }, () => done(true));
        } else {
          done(true);
        }

      }).error(error => {
        console.warn(error);
        this.setState({
          lang: (this.state.keycloak.userInfo as any)['locale']
        }, () => done(true));
      });
    }).error(error => {
      console.error(error);
      done(false);
    });


  }

  private connectWebSocket() {

    this.setState({
      connectionStatus: 'connectingWs'
    }, () => {
      let webSocketAddress = (location.protocol === 'https:' ? 'wss:' : 'ws:') + '//' + window.location.host + location.pathname;
      if (!webSocketAddress.endsWith('/')) {
        webSocketAddress += '/';
      }
      webSocketAddress += 'ws';

      let wsMgr = new WsConnectionManager(webSocketAddress, this.state.keycloak);
      wsMgr.onConnected = () => {

        wsMgr.request({action: 'db-appconfig-get'}, response => {
          let newState: any = {
            wsManager       : wsMgr,
            connectionStatus: 'connected',
            appConfig: response.result as AppConfig
          };
          if(newState.appConfig.defaultLanguage != null) {
            newState.lang = newState.appConfig.defaultLanguage;
          }
          this.setState(newState);
        });
      };

      wsMgr.onCloseByServer = () => {
        this.setState({
          wsManager       : null,
          connectionStatus: 'connectingWs'
        }, () => {
          setTimeout(() => this.connectWebSocket(), 3000);
        });
      };
      wsMgr.onConnectionFailed = () => {
        console.error('Could not connect WebSocket');
        setTimeout(() => this.connectWebSocket(), 3000);
      };
      wsMgr.onDisconnected = () => {
        this.setState({
          wsManager       : null,
          connectionStatus: 'disconnected'
        });
        console.info('Disconnected');
      };
      wsMgr.connect();

    });
  }

  private resetLanguage(newValue: string) {
    i18n.changeLanguage(newValue, () => {
      this.setState({lang: newValue as Language})
    });
  }

  render() {

    let modal;
    switch (this.state.connectionStatus) {
      case 'init': {
        return <Dialog open={true} disableBackdropClick={true}>
          <Grid container direction='column' spacing={3} alignItems='center' style={{padding: '20px'}}>
            <Grid item>
              <div style={{display: 'flex', justifyContent: 'center', width: '400px'}}>
                <img src={VRLogoPlain} style={{width: '400px'}}/>
              </div>
            </Grid>
            <Grid item>
              <Typography>Initializing security handlers</Typography>
            </Grid>
            <Grid item>
              <SecurityIcon className='rotate' style={{
                height: '128px',
                width : 'auto',
                color : this.props.theme.palette.primary.main
              }}/>
            </Grid>
            <Grid item>
              <img src={VRLogo} style={{width: '150px'}}/>
            </Grid>
          </Grid>
        </Dialog>;
      }
      case 'denied': {
        return <Dialog open={true} disableBackdropClick={true}>
          <Grid container direction='column' spacing={3} alignItems='center' style={{padding: '20px'}}>
            <Grid item>
              <div style={{display: 'flex', justifyContent: 'center', width: '400px'}}>
                <img src={VRLogoPlain} style={{width: '400px'}}/>
              </div>
            </Grid>
            <Grid item>
              <Typography>Access denied</Typography>
            </Grid>
            <Grid item>
              <AccessDeniedIcon className='rotate' style={{
                height: '128px',
                width : 'auto',
                color : this.props.theme.palette.error.main
              }}/>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary'>Try again</Button>
            </Grid>
            <Grid item>
              <img src={VRLogo} style={{width: '150px'}}/>
            </Grid>
          </Grid>
        </Dialog>;
      }
      case 'disconnected': {
        return <Dialog open={true} disableBackdropClick={true}>
          <Grid container direction='column' spacing={3} alignItems='center' style={{padding: '20px'}}>
            <Grid item>

              <div style={{display: 'flex', justifyContent: 'center', width: '400px'}}>
                <img src={VRLogoPlain} style={{width: '400px'}}/>
              </div>
            </Grid>
            <Grid item>
              <Typography>Disconnected</Typography>
            </Grid>
            <Grid item>
              <img src={VRLogo} style={{width: '150px'}}/>
            </Grid>
          </Grid>
        </Dialog>
      }
      case 'connectingWs': {
        modal = <Dialog key='modal' open={true} disableBackdropClick={true}>
          <Grid container direction='column' spacing={3} alignItems='center' style={{padding: '20px'}}>
            <Grid item>
              <div style={{display: 'flex', justifyContent: 'center', width: '400px'}}>
                <img src={VRLogoPlain} style={{width: '400px'}}/>
              </div>
            </Grid>
            <Grid item>
              <Typography>Connecting backend</Typography>
            </Grid>
            <Grid item>
              <ConnectingIcon className='fade' style={{
                height: '128px',
                width : 'auto',
                color : this.props.theme.palette.primary.main
              }}/>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary' onClick={() => {
                this.state.keycloak.logout();
                location.replace('/');
              }}>Logout</Button>
            </Grid>
            <Grid item>
              <img src={VRLogo} style={{width: '150px'}}/>
            </Grid>
          </Grid>
        </Dialog>
      }
    }


    let mainContent;
    if (this.state.connectionStatus === 'connected') {
      switch (this.state.stage) {
        case 'home': {
          mainContent =
            <HomePage wsManager={this.state.wsManager} lang={this.state.lang} onTrainingSelected={(training) => {
              this.setState({selectedTraining: training, stage: 'training'})
            }}/>;
        }
          break;
        case 'training': {
          mainContent = <div style={{paddingTop: '20px'}}>
            <TrainingPage training={this.state.selectedTraining} wsManager={this.state.wsManager} appConfig={this.state.appConfig!} {...this.state} onClose={() => this.setState({
              selectedTraining: null,
              stage           : 'home'
            })}/>
          </div>;
        }
      }
    }

    let content = [modal];
    if(this.state.connectionStatus === 'connected') {
      content.push(<AppBar position="static" key='navBar' elevation={0} style={{backgroundColor: this.state.appConfig.headerColor}}>
        <Toolbar>
          <div style={{display: 'flex', height: '107px', width: '100vw', alignItems: 'center'}}>
            <img src={VRLogo} style={{height: '70px'}}/>
            <span style={{flexGrow: 1}}/>
            <UserMenu keycloak={this.state.keycloak} {...this.props}/>
            <Select value={this.state.lang} onChange={(event) => {
              this.resetLanguage(event.target.value as string);
            }
            }>
              {this.state.appConfig.availableLanguages.map(lang =>{
                let icon;
                switch (lang) {
                  case 'fr': icon = FR_LANG_ICON;break;
                  case 'en': icon = EN_LANG_ICON;break;
                  case 'de': icon = DE_LANG_ICON;break;
                }
                return <MenuItem key={lang} value={lang}><img style={{width: '30px'}} src={icon}/></MenuItem>
              })}
            </Select>
          </div>
        </Toolbar>
      </AppBar>);
      content.push(
        <div key='content'>
          {mainContent}
        </div>
      );
    }
    content.push(<Footer key='footer'/>);

    return content;
  }
}

export default withTheme(withTranslation()(LandingPage));