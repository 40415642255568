import * as React from 'react';
import { Component } from 'react';
import { Language, OrganisationalUnit, OrganisationalUnitType, Test, TestResult, Training, TrainingModule, TrainingSession } from '../api/Api';
import { WithTranslation } from 'react-i18next';
import { MenuItem, Paper, Select, Switch, Typography, WithTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ChartConfiguration } from 'chart.js';
import ChartJs from './ChartJs';
import DataHelper from '../misc/DataHelper';
import MyPalette from '../misc/MyPalette';

interface ResultsPerOrgUnitProps extends WithTranslation, WithTheme {
  lang: Language;
  training: Training;
  modules?: TrainingModule[];
  orgUnitType?: OrganisationalUnitType;
  orgUnits?: OrganisationalUnit[];
  sessions?: TrainingSession[];
  tests?: Test[];
  results?: TestResult[];
}

interface ResultsPerOrgUnitState {
  options?: Partial<ChartConfiguration>;
  parentOus?: OrganisationalUnit[];
  selectedParent?: OrganisationalUnit;
  valuesDisplay: 'abs' | 'percent';
}

export default class ResultsPerOrgUnit extends Component<ResultsPerOrgUnitProps, ResultsPerOrgUnitState> {

  private chart: ChartJs;
  private chartOptions: Partial<ChartConfiguration> = {
    type   : 'bar',
    data   : {},
    options: {
      legend    : {
        position: 'bottom'
      },
      responsive: true,
      //aspectRatio: 3,
      scales    : {
        yAxes: [{
          scaleLabel: {
            lineHeight: 1.6,
          },
          ticks     : {
            beginAtZero: true
          }
        }]
      }
    }
  };

  constructor(props: ResultsPerOrgUnitProps) {
    super(props);
    this.state = {
      valuesDisplay: 'abs'
    };
  }

  componentDidMount(): void {
    this.updateData();
  }

  componentDidUpdate(prevProps: Readonly<ResultsPerOrgUnitProps>, prevState: Readonly<ResultsPerOrgUnitState>, snapshot?: any): void {
    //console.debug('Component did update', this.props.results, prevProps.results, this.props.results !== prevProps.results, this.props.results != prevProps.results)
    if (this.props.results !== prevProps.results || this.props.lang !== prevProps.lang) {
      this.updateData();
    }
  }

  private updateData() {


    if (this.props.modules != null) {
      let parentOus: OrganisationalUnit[];

      let modulesOfTraining = this.props.modules.filter(module => module.trainingKey === this.props.training.key);
      let testsOfModules = modulesOfTraining.map(module =>
        this.props.tests.filter(test => test.trainingKey === this.props.training.key && test.moduleKey === module.key)
      );

      if (this.props.orgUnitType.parentType != null) {
        parentOus = this.props.orgUnits.filter(ou => ou.typeKey === this.props.orgUnitType.parentType).sort((a, b) => a.key - b.key);
      }

      let tmpData: any = {
        labels  : [],
        datasets: []
      };

      let organisationalUnits;
      if (this.state.selectedParent == null) {
        organisationalUnits = this.props.orgUnits.filter(ou => ou.typeKey === this.props.orgUnitType.key);
      } else {
        organisationalUnits = this.props.orgUnits.filter(ou => ou.typeKey === this.props.orgUnitType.key && ou.parentKey === this.state.selectedParent.key);
      }

      tmpData.labels = organisationalUnits.map(ou => ou.name[this.props.lang]);
      if (organisationalUnits.length > 10) {
        this.chartOptions.type = 'horizontalBar';
        this.chartOptions.options.maintainAspectRatio = false;
      }

      organisationalUnits.forEach((ou, ouIndex) => {

        if (tmpData.labels.length == ouIndex) {
          tmpData.labels[ouIndex] = ou.name[this.props.lang];
        }

        let resultsOfOu = this.props.results.filter(res => res.organisational_units[this.props.orgUnitType.key] === ou.key);


        modulesOfTraining.forEach((module, moduleIndex) => {
          let nbModuleValidated = 0;
          let nbModuleFailed = 0;
          if (tmpData.datasets.length == moduleIndex * 2) {
            tmpData.datasets.push({
              label          : module.name[this.props.lang] + ' ' + this.props.t('Charts.passed'),
              backgroundColor: MyPalette.getSuccessColor(moduleIndex, modulesOfTraining.length),
              stack          : module.key,
              data           : []
            });
            tmpData.datasets.push({
              label          : module.name[this.props.lang] + ' ' + this.props.t('Charts.failed'),
              backgroundColor: MyPalette.getSecondaryColor(moduleIndex, modulesOfTraining.length),
              stack          : module.key,
              data           : []
            });
          }

          let resultsOfTheModuleInSessionForOu = resultsOfOu.filter(res => res.moduleKey === module.key);
          resultsOfTheModuleInSessionForOu.forEach(runResult => {
            if (DataHelper.isModulePassed(module, testsOfModules[moduleIndex], runResult.results)) {
              nbModuleValidated++;
            } else {
              nbModuleFailed++;
            }
          });

          if (this.state.valuesDisplay == 'abs') {
            tmpData.datasets[2 * moduleIndex].data[ouIndex] = nbModuleValidated;
            tmpData.datasets[(2 * moduleIndex) + 1].data[ouIndex] = nbModuleFailed;
          } else {
            let total = nbModuleValidated + nbModuleFailed;
            tmpData.datasets[2 * moduleIndex].data[ouIndex] = Math.round((nbModuleValidated * 100) / total);
            tmpData.datasets[(2 * moduleIndex) + 1].data[ouIndex] = Math.round((nbModuleFailed * 100) / total);
          }


        });
      });
      this.updateGraphDataset(tmpData, parentOus);
    }
  }

  private updateGraphDataset(newData: any, parentOus: OrganisationalUnit[]) {
    let newOptions: ChartConfiguration = Object.create(this.chartOptions);
    //newOptions.data.labels = newData.map(data => data.legend);
    newOptions.data = newData;
    this.setState({
      options  : newOptions,
      parentOus: parentOus
    });
  }

  render() {

    return <Paper style={{
      padding    : '10px',
      marginTop  : '16px',
      marginRight: '16px',
      width      : '80wv',
      border     : '1px solid ' + this.props.theme.palette.primary.light
    }} square={true}>
      <Grid container direction='column' spacing={3}>
        <Grid item container>
          <Grid item container xs={6}>
            <Grid item>
              <Typography>{this.props.t('Charts.ResultsPerOrgUnit.title', {ouType: this.props.orgUnitType.name[this.props.lang]})}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} container direction={'row-reverse'} spacing={3}>
            <Grid item>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>{this.props.t('Charts.quantity')}</Grid>
                  <Grid item>
                    <Switch
                      size='small'
                      checked={this.state.valuesDisplay == 'percent'}
                      onChange={evt => this.setState({valuesDisplay: evt.target.checked ? 'percent' : 'abs'}, () => this.updateData())}
                      value="checkedC"
                    />
                  </Grid>
                  <Grid item>%</Grid>
                </Grid>
              </Typography>
            </Grid>
            {this.state.parentOus != null ?
              <Grid item>
                <Select
                  value={this.state.selectedParent != null ? this.state.selectedParent.key : '@@@@@'}
                  onChange={(event) => {
                    let newParent = null;
                    if (event.target.value != '@@@@@') {
                      newParent = this.state.parentOus.find(p => p.key === event.target.value);
                    }
                    this.setState({selectedParent: newParent}, () => this.updateData());
                  }}>
                  <MenuItem key='all' value={'@@@@@'}>{this.props.t('Actions.all')}</MenuItem>
                  {this.state.parentOus.map(ou => {
                    return <MenuItem key={ou.key} value={ou.key}>{ou.name[this.props.lang]}</MenuItem>;
                  })}
                </Select>
              </Grid>
              : null}
          </Grid>
        </Grid>
        <Grid item>
          {this.state.options != null ?
            <ChartJs options={this.state.options} ref={(me) => this.chart = me}/>
            : null}
        </Grid>
      </Grid>
    </Paper>;
  }
}