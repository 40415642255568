import * as React from 'react';
import { Component } from 'react';
import { ApiRequest, ApiResponse, Headset, Language, TrainingSession, TopicNotification, Training } from '../api/Api';
import { Paper, Typography, WithTheme } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { WithTranslation } from 'react-i18next';
import HeadsetCommands from '../misc/HeadsetCommands';

interface HeadsetsListProps extends WithTranslation, WithTheme {
  wsManager: WsConnectionManager;
  lang: Language;
  session: TrainingSession;
  training: Training;
}

interface HeadsetsListState {
  ready: boolean;
  availableHeadsets: Headset[];
}

export default class HeadsetsList extends Component<HeadsetsListProps, HeadsetsListState> {

  private topicRegistrationId: number;

  constructor(props: HeadsetsListProps) {
    super(props);
    this.state = {
      ready            : false,
      availableHeadsets: []
    };
  }

  componentDidMount(): void {
    //register
    this.loadAvailableHeadsets();
  }

  componentWillUnmount(): void {
    if (this.topicRegistrationId != null) {
      this.props.wsManager.unregister(this.topicRegistrationId);
    }
  }

  private loadAvailableHeadsets() {
    let request: Partial<ApiRequest> = {
      action: 'db-headsets-available-list'
    };

    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve available headsets', response);
      } else {
        this.setState({availableHeadsets: response.result as Headset[]}, () => {
          if (this.topicRegistrationId == null) {
            this.props.wsManager.registerForTopic('headsets',
              (registrationId)=> this.topicRegistrationId = registrationId,
              notification => this.notificationReceived(notification));
          }
        });
      }
    });
  }

  private notificationReceived(notification: TopicNotification) {
    this.loadAvailableHeadsets();
  }

  private sendEnrollmentRequest(serialNumber: string) {
    HeadsetCommands.enrollOne(
      this.props.wsManager,
      response => {
        if (response['status'] !== 'ok') {
          console.error('Could not enroll headset', response);
        }
        this.loadAvailableHeadsets();
      },
      serialNumber,this.props.training.key, this.props.session.key
    );
  }

  render() {
    return <Paper style={{paddingLeft: '30px', paddingRight: '30px'}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant='body2' style={{color: this.props.theme.palette.primary.main}}>{this.props.t('HeadsetPanel.commonName')}</Typography></TableCell>
            <TableCell><Typography variant='body2' style={{color: this.props.theme.palette.primary.main}}>{this.props.t('HeadsetPanel.serialNumber')}</Typography></TableCell>
            <TableCell><Typography variant='body2' style={{color: this.props.theme.palette.primary.main}}>{this.props.t('HeadsetPanel.status')}</Typography></TableCell>
            <TableCell><Typography variant='body2' style={{color: this.props.theme.palette.primary.main}}>{this.props.t('HeadsetPanel.kind')}</Typography></TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody style={{maxHeight: '40vh', minHeight: '40vh', height: '40vh', overflowY:'scroll', overflow: 'scroll'}}>
          {this.state.availableHeadsets.map((headset, idx) => {
            return <TableRow key={headset.serialNumber}>
              <TableCell>{headset.commonName}</TableCell>
              <TableCell>{headset.serialNumber}</TableCell>
              <TableCell>{headset.status}</TableCell>
              <TableCell>{headset.kind}</TableCell>
              <TableCell><Button variant='contained' color='primary' onClick={()=>this.sendEnrollmentRequest(headset.serialNumber)}>{this.props.t('Actions.include')}</Button></TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </Paper>;
  }
}