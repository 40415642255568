import * as React from 'react';
import { Component } from 'react';
import { Grid, Theme, WithStyles, WithTheme, createStyles, Button, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { ApiRequest, AppConfig, Language, OrganisationalUnit, OrganisationalUnitType, Test, TestResult, Training, TrainingModule, TrainingSession } from '../api/Api';
import { WithTranslation, withTranslation } from 'react-i18next';
import withTheme from '@material-ui/core/styles/withTheme';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withStyles, { CSSProperties } from '@material-ui/core/styles/withStyles';
import GeneralStats from '../statsPages/GeneralStats';
import DeferCounter from '../misc/CoreDeferCounter';
import ComingSoon from '../cmp/ComingSoon';
import ModulesStats from '../statsPages/ModulesStats';
import withWidth, { isWidthUp, WithWidth } from '@material-ui/core/withWidth';
import MatriculesStats from '../statsPages/MatriculesStats';
import ResultsPerOrgUnit from '../chart/ResultsPerOrgUnit';
import * as moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';
import { DateTimePicker } from "@material-ui/pickers";

interface TrainingStatsProps extends WithTheme, WithTranslation, WithStyles, WithWidth {
  wsManager: WsConnectionManager;
  lang: Language;
  training: Training;
  appConfig: AppConfig;
}

interface TrainingStatsState {
  currentTab: string;
  orgUnitTypes: OrganisationalUnitType[];
  orgUnits?: OrganisationalUnit[];
  modules?: TrainingModule[];
  tests?: Test[];
  results?: TestResult[];
  sessions?: TrainingSession[];
  from: moment.Moment;
  to: moment.Moment;
}

const localStyles = (theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
    },
    selected: {
      backgroundColor: theme.palette.background.paper,
    },
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'start',
      fontWeight: 'bold'
    }
  });


class TrainingStats extends Component<TrainingStatsProps, TrainingStatsState> {

  private topicRegistrationId: number;
  private loadDefer: DeferCounter;

  constructor(props: TrainingStatsProps) {
    super(props);
    moment.locale(this.props.lang);
    this.state = {
      currentTab: 'overview',
      orgUnitTypes: [],
      from: moment().subtract(6, 'months').startOf('day'),
      to: moment().endOf('day')
    };
  }

  componentDidMount(): void {
    this.loadData();
  }

  componentWillUnmount(): void {
    if (this.topicRegistrationId != null) {
      this.props.wsManager.unregister(this.topicRegistrationId);
    }
  }

  private registerTopic() {
    if (this.topicRegistrationId == null) {
      this.props.wsManager.registerForTopic('results',
        (registrationId) => this.topicRegistrationId = registrationId,
        notification => {
          //console.log("notification", notification);
          if (notification.details['trainingKey'] === this.props.training.key) {
            this.loadResults((res => {
              this.setState({ results: res });
            }));
          }
        });
    }
  }

  private outSortFunction = (u1: OrganisationalUnitType, u2: OrganisationalUnitType) => {
    if (u1.index != null && u2.index != null) {
      return u1.index - u2.index;
    } else {
      return u1.name[this.props.lang].localeCompare(u2.name[this.props.lang]);
    }
  };

  private loadData() {
    //nothing is currently loading
    if (this.loadDefer == null) {

      let orgUnitTypes: OrganisationalUnitType[];
      let orgUnits: OrganisationalUnit[];
      let modules: TrainingModule[];
      let tests: Test[];
      let sessions: TrainingSession[];
      let testResults: TestResult[];


      this.loadDefer = new DeferCounter(6);
      this.loadDefer.then(() => {
        this.setState({
          orgUnitTypes: orgUnitTypes,
          orgUnits: orgUnits,
          modules: modules,
          tests: tests,
          sessions: sessions,
          results: testResults
        }, () => {
          this.registerTopic();
        });
      });
      this.getOrgUnitTypes(OUTypes => {
        orgUnitTypes = OUTypes;
        this.loadDefer.count();
      });
      this.getOrgUnits(OUnits => {
        orgUnits = OUnits;
        this.loadDefer.count();
      });
      this.loadTrainingModules(mods => {
        modules = mods;
        this.loadDefer.count();
      });
      this.loadTests(tst => {
        tests = tst;
        this.loadDefer.count();
      });
      this.loadTrainingSessions(ses => {
        sessions = ses;
        this.loadDefer.count();
      });
      this.loadResults(res => {
        testResults = res;
        this.loadDefer.count();
      });
    }

  }


  private getOrgUnitTypes(cb: (res: OrganisationalUnitType[]) => void) {
    let request = { action: 'db-organisationalUnitTypes-list' } as ApiRequest;
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve organisationalUnitTypes', response);
        cb(null);
      } else {
        cb((response.result as OrganisationalUnitType[]).sort(this.outSortFunction))
      }
    });
  }

  private getOrgUnits(cb: (res: OrganisationalUnit[]) => void) {
    let request = { action: 'db-organisationalUnits-list' } as ApiRequest;
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve organisationalUnits', response);
        cb(null);
      } else {
        cb((response.result as OrganisationalUnit[]))
      }
    });
  }

  private loadTrainingModules(cb: (res: TrainingModule[]) => void) {
    let request: ApiRequest = {
      action: 'db-trainingModules-list',
      parameters: {
        trainingKey: this.props.training.key
      }
    };
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve trainingModules', response);
        cb(null);
      } else {
        cb((response.result as TrainingModule[]).sort((m1, m2) => m1.key.localeCompare(m2.key)));
      }
    });
  }

  private loadTests(cb: (res: Test[]) => void) {
    let request: ApiRequest = {
      action: 'db-tests-list',
      parameters: {
        trainingKey: this.props.training.key,
        //moduleKey: ##
      }
    };
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve trainingModules', response);
        cb(null);
      } else {
        cb(response.result as Test[]);
      }
    });
  }

  private loadTrainingSessions(cb: (res: TrainingSession[]) => void) {
    let request: Partial<ApiRequest> = {
      action: 'db-trainingSessions-list',
      parameters: {
        'trainingKey': this.props.training.key
      }
    };

    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve training sessions', response);
        cb(null);
      } else {
        let dateMin = this.state.from.toDate().getTime();
        let dateMax = this.state.to.toDate().getTime();
        let localResults = response.result as TrainingSession[];
        let filteredResults = localResults.filter(session => session.creationDate >= dateMin && session.creationDate <= dateMax);
        cb(filteredResults);
      }
    })
  }

  private loadResults(cb: (res: TestResult[]) => void) {
    let request: ApiRequest = {
      action: 'db-testResults-list',
      parameters: {
        trainingKey: this.props.training.key,
        //trainingSessionKey: ##
        //moduleKey: ##
        //centerKey: ##
        //serviceKey: ##
        //jobKey: ##
      }
    };
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve trainingModules', response);
        cb(null);
      } else {
        let dateMin = this.state.from.toDate().getTime() / 1000;
        let dateMax = this.state.to.toDate().getTime() / 1000;
        let localResults = response.result as TestResult[];
        let filteredResults = localResults.filter(testResult => testResult.timestamp >= dateMin && testResult.timestamp <= dateMax);
        cb(filteredResults);
      }
    });
  }

  private dateChanged() {
    this.loadTrainingSessions(sessions => {
      this.loadResults(testResults => {
        this.setState({
          sessions: sessions,
          results: testResults
        });
      });
    });
  }


  private getBulkResultsForExport(cb: (res: any[]) => void) {
    let request = {
      action: 'db-bulk-results-get',
      parameters: {
        trainingKey: this.props.training.key
      }
    } as ApiRequest;
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve bulk-results for export', response);
        cb(null);
      } else {
        cb(response.result as any[]);
      }
    });
  }

  private detailedExportToCsv() {

    let csvContent = 'Session\tRunId';

    let organizationalUnitTypes = this.state.orgUnitTypes;
    organizationalUnitTypes.forEach(out => {
      csvContent += '\t' + out.name[this.props.lang];
    });
    let trainingTests = this.state.tests.filter(test => test.trainingKey === this.props.training.key);
    let orderedTests = trainingTests.sort((t1, t2) => {
      let moduleCmp = t1.moduleKey.localeCompare(t2.moduleKey);
      if (moduleCmp === 0) {
        return t1.testKey.localeCompare(t2.testKey);
      } else {
        return moduleCmp;
      }
    });

    let orderedModules = this.state.modules.sort((m1, m2) => m1.key.localeCompare(m2.key));
    orderedModules.forEach(module => {
      csvContent += '\t' + module.name[this.props.lang] + '_GameOver';
      let testsOfTheModule = orderedTests.filter(test => test.moduleKey === module.key);
      testsOfTheModule.forEach(test => {
        csvContent += '\t' + module.name[this.props.lang] + '_' + test.name[this.props.lang] + '_' + 'value';
        csvContent += '\t' + module.name[this.props.lang] + '_' + test.name[this.props.lang] + '_' + 'success';
      });
    });


    csvContent += '\n';//END OF HEADER

    this.state.sessions.forEach(session => {
      let resultsOfSession = this.state.results.filter(res => res.trainingSessionKey === session.key);
      let runs: string[] = [];
      resultsOfSession.forEach(res => {
        if (runs.indexOf(res.runId) == -1) {
          runs.push(res.runId);
        }
      });
      runs.forEach(runId => {
        let runResults = resultsOfSession.filter(res => res.runId === runId);

        csvContent += session.key;
        csvContent += '\t' + runId;
        organizationalUnitTypes.forEach(out => {
          let ouKey = runResults[0].organisational_units[out.key];
          let ou = this.state.orgUnits.find(ou => ou.typeKey === out.key && ou.key == ouKey);
          csvContent += '\t' + (ou == null ? 'undefined(t:' + out.key + ', k:' + ouKey + ')' : '"' + ou.name[this.props.lang] + '"');
        });

        orderedModules.forEach(module => {
          let testsOfTheModule = orderedTests.filter(test => test.moduleKey === module.key);
          let moduleRes = runResults.find(res => res.moduleKey === module.key);
          if (moduleRes == null) {
            csvContent += '\t\t\t'
          } else {
            csvContent += '\t' + moduleRes.gameOver;
            testsOfTheModule.forEach(test => {
              let resultOfTest = moduleRes.results.find(res => res.testKey === test.testKey);
              csvContent += '\t' + resultOfTest.answer;
              csvContent += '\t' + (resultOfTest.answer === test.answer);
            });
          }
        });
        csvContent += '\n';///End result
      });
    });


    let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    let url = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', this.props.training.name[this.props.lang] + '_AllResults.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  private lightExportToCsv() {

    let header = ['identifyer', 'date', 'time', 'duration', 'module', 'trainer', 'validated', 'score', 'max_score', 'minor_mistake', 'major_mistake'].join('\t');

    let content = "";
    this.getBulkResultsForExport(bulkResults => {
      bulkResults.forEach(resultsOfId => {
        resultsOfId.records.forEach((result: any) => {

          let moduleName = result.moduleId;
          let module = this.state.modules.find(m => m.key === result.moduleId);
          if (module != null) {
            moduleName = module.name[this.props.lang];
          }

          let nbMinorFault = 0;
          let nbMajorFault = 0;
          if (module.type == 'Checklist') {
            nbMinorFault = (result.logs != null && result.logs.length != 0 ? result.logs.filter((t: any) => t.answerCorrect != t.answerUser).length : 0);
          } else {
            nbMinorFault = (result.logs != null ? result.logs.filter((t: any) => t.faultType == 'EFaultType_FAULT').length : 0);
            nbMajorFault = (result.logs != null ? result.logs.filter((t: any) => t.faultType != 'EFaultType_FAULT').length : 0);
          }

          let playTime = moment.utc(Number(result.playTime.substring(0, result.playTime.lastIndexOf('.'))) * 1000);

          content += [
            result.pontierMatricule,
            moment(result.lastModified).format('DD/MM/YYYY'),
            moment(result.lastModified).format('HH:mm:ss'),
            playTime.format('H:mm:ss'),
            moduleName,
            result.formateurMatricule,
            result.formateurValidation,
            result.scoreClamped,
            100,
            nbMinorFault,
            nbMajorFault
          ].join('\t') + '\n';
        });
      });
      let csvContent = header + '\n' + content;

      let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      let url = URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', this.props.training.name[this.props.lang] + '_AllResults.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });


  }

  private bulkExportToCsv() {

    let csvContent = 'Session\tRunId';

    let organizationalUnitTypes = this.state.orgUnitTypes;
    organizationalUnitTypes.forEach(out => {
      csvContent += '\t' + out.name[this.props.lang];
    });
    let trainingTests = this.state.tests.filter(test => test.trainingKey === this.props.training.key);
    let orderedTests = trainingTests.sort((t1, t2) => {
      let moduleCmp = t1.moduleKey.localeCompare(t2.moduleKey);
      if (moduleCmp === 0) {
        return t1.testKey.localeCompare(t2.testKey);
      } else {
        return moduleCmp;
      }
    });

    let orderedModules = this.state.modules.sort((m1, m2) => m1.key.localeCompare(m2.key));
    orderedModules.forEach(module => {
      csvContent += '\t' + module.name[this.props.lang] + '_GameOver';
      let testsOfTheModule = orderedTests.filter(test => test.moduleKey === module.key);
      testsOfTheModule.forEach(test => {
        csvContent += '\t' + module.name[this.props.lang] + '_' + test.name[this.props.lang] + '_' + 'value';
        csvContent += '\t' + module.name[this.props.lang] + '_' + test.name[this.props.lang] + '_' + 'success';
      });
    });


    csvContent += '\n';//END OF HEADER

    this.state.sessions.forEach(session => {
      let resultsOfSession = this.state.results.filter(res => res.trainingSessionKey === session.key);
      let runs: string[] = [];
      resultsOfSession.forEach(res => {
        if (runs.indexOf(res.runId) == -1) {
          runs.push(res.runId);
        }
      });
      runs.forEach(runId => {
        let runResults = resultsOfSession.filter(res => res.runId === runId);

        csvContent += session.key;
        csvContent += '\t' + runId;
        organizationalUnitTypes.forEach(out => {
          let ouKey = runResults[0].organisational_units[out.key];
          let ou = this.state.orgUnits.find(ou => ou.typeKey === out.key && ou.key == ouKey);
          csvContent += '\t' + (ou == null ? 'undefined(t:' + out.key + ', k:' + ouKey + ')' : '"' + ou.name[this.props.lang] + '"');
        });

        orderedModules.forEach(module => {
          let testsOfTheModule = orderedTests.filter(test => test.moduleKey === module.key);
          let moduleRes = runResults.find(res => res.moduleKey === module.key);
          if (moduleRes == null) {
            csvContent += '\t\t\t'
          } else {
            csvContent += '\t' + moduleRes.gameOver;
            testsOfTheModule.forEach(test => {
              let resultOfTest = moduleRes.results.find(res => res.testKey === test.testKey);
              csvContent += '\t' + resultOfTest.answer;
              csvContent += '\t' + (resultOfTest.answer === test.answer);
            });
          }
        });
        csvContent += '\n';///End result
      });
    });


    let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    let url = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', this.props.training.name[this.props.lang] + '_AllResults.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }


  render() {

    let statsComponent;
    switch (this.state.currentTab) {
      case 'overview': {
        if (this.props.appConfig.statsParams.useBypassInOverview != null && this.props.appConfig.statsParams.useBypassInOverview) {
          statsComponent = <iframe src='ir/pages/index.html' style={{ border: 'none', width: '100%' }} onLoad={(event) => event.currentTarget.style.height = event.currentTarget.contentDocument.body.scrollHeight + 'px'} />;
        } else {
          statsComponent = <GeneralStats {...this.props} {...this.state} />
        }
      } break;
      case 'module': {
        statsComponent = <ModulesStats  {...this.props} {...this.state} />;
      } break;
      case 'tab_matricules': {
        statsComponent = <MatriculesStats  {...this.props} {...this.state} />;
      } break;
      default: {
        //statsComponent = <ComingSoon {...this.props}/>;
        statsComponent = <ResultsPerOrgUnit key={this.state.currentTab} {...this.props} {...this.state} orgUnitType={this.state.orgUnitTypes.find(ou => ou.key === this.state.currentTab)} />;
      }
    }

    let tabs = [];
    tabs.push(<Tab key={'overview'} label={
      <Typography variant='body2' align='left'>{this.props.t('TrainingStats.overviewTab')}</Typography>} value='overview' classes={this.props.classes} />);
    if (this.props.appConfig.statsParams.displayStatsPerModule) {
      tabs.push(<Tab key={'module'} label={
        <Typography variant='body2' align='left'>{this.props.t('TrainingStats.moduleTab')}</Typography>} value='module' classes={this.props.classes} />);
    }
    if (this.props.appConfig.statsParams.manualTabs != null) {
      this.props.appConfig.statsParams.manualTabs.forEach(tabObject => {
        tabs.push(<Tab key={tabObject.key} value={tabObject.key} classes={this.props.classes} label={
          <Typography variant='body2' align='left'>{this.props.t('TrainingStats.organisationalUnitTypeTab', { 'out': tabObject.name[this.props.lang] })}</Typography>} />);
      });
    }
    this.state.orgUnitTypes.forEach(orgUnitType => {
      tabs.push(<Tab key={orgUnitType.key} value={orgUnitType.key} classes={this.props.classes} label={
        <Typography variant='body2' align='left'>{this.props.t('TrainingStats.organisationalUnitTypeTab', { 'out': orgUnitType.name[this.props.lang].toLowerCase() })}</Typography>} />);
    });

    let downloadButtonStyle: Partial<CSSProperties> = {
      marginTop: '20px',
      marginBottom: '15px'
    };
    let downloadButton;
    switch (this.props.appConfig.statsParams.exportType) {
      case 'full': {
        downloadButton = <Button size={'small'} variant={'outlined'} color="primary" style={downloadButtonStyle} onClick={() => this.detailedExportToCsv()}>{this.props.t('Actions.download')}</Button>;
      } break;
      case 'light': {
        downloadButton = <Button size={'small'} variant={'outlined'} color="primary" style={downloadButtonStyle} onClick={() => this.lightExportToCsv()}>{this.props.t('Actions.download')}</Button>;
      } break;
      case 'bulk': {
        downloadButton = <Button size={'small'} variant={'outlined'} color="primary" style={downloadButtonStyle} onClick={() => this.bulkExportToCsv()}>{this.props.t('Actions.download')}</Button>;
      } break;
    }

    let timeFilters;
    if (this.props.appConfig.statsParams.displayTimeFilters) {
      timeFilters = <Grid item>
        <div style={{
          marginTop: '20px',
          marginBottom: '15px'
        }}>
          <MuiThemeProvider theme={createMuiTheme({
            typography: {
              htmlFontSize: 10,
              fontFamily: 'Open Sans',
              allVariants: {
                color: '#7b838b'
              }
            }
          })}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={this.props.lang}>
              <Grid container spacing={2}>
                <Grid item>
                  <DateTimePicker ampm={false} label={this.props.t('Actions.from')} format="DD/MM/YYYY HH:mm" value={this.state.from} onChange={(newDate: any) => this.setState({ from: newDate }, () => this.dateChanged())} />
                </Grid>
                <Grid item>
                  <DateTimePicker ampm={false} label={this.props.t('Actions.to')} format="DD/MM/YYYY HH:mm" value={this.state.to} onChange={(newDate: any) => this.setState({ to: newDate }, () => this.dateChanged())} />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </div>

      </Grid>;
    }

    return <Grid container direction='column'>
      <Grid item container style={{ alignItems: 'center' }} spacing={2}>
        <Grid item>
          <Typography variant='h2' style={{
            marginTop: '20px',
            marginBottom: '15px',
            color: '#42454E'
          }}>{this.props.t('TrainingStats.title')}</Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        {timeFilters}
        <Grid item>{downloadButton}</Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Tabs
            value={this.state.currentTab}
            onChange={(e, val) => this.setState({ currentTab: val })}
            //orientation={isWidthUp('lg', this.props.width) ? "vertical" : "horizontal"}
            orientation={"horizontal"}
            indicatorColor="primary"
            textColor="primary"
            style={{
              backgroundColor: this.props.theme.palette.grey['200']
            }}
          >
            {tabs}
          </Tabs>
        </Grid>
        <Grid item style={{ marginTop: '0px' }} xs={12}>
          <div style={{
            minHeight: '40vh',
            padding: '30px 2%',
            backgroundColor: this.props.theme.palette.background.paper
          }}>
            {statsComponent}
          </div>
        </Grid>
      </Grid>
    </Grid>;
    /*
    */
  }
}

export default withWidth()(withStyles(localStyles)(withTheme(withTranslation()(TrainingStats))));
