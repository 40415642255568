import * as React from 'react';
import { Component } from 'react';
import { Paper, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import withTheme from '@material-ui/core/styles/withTheme';
import Grid from '@material-ui/core/Grid';
import { BreadcrumbsStep } from '../api/Api';

interface HeaderProps {
  theme: Theme;
  steps: BreadcrumbsStep[];
}

interface HeaderState {
}

class Header extends Component<HeaderProps, HeaderState> {

  constructor(props: HeaderProps) {
    super(props);
    this.state = {};
  }

  render() {

    return <div style={{display: 'flex'}}>
      <img src={'ir/ClientLogo.png'} style={{height: '10vh', marginRight: '20px'}}/>
      <Paper elevation={0} style={{display: 'inline-flex', flexGrow: 1}} square={true}>
        <div style={{display: 'flex', flexGrow: 1, backgroundColor: 'rgb(122,181,255, 0.1)'}}>
          <div style={{
            flexGrow     : 1,
            flexDirection: 'column',
            opacity      : 1,
            borderLeft   : '4px solid ' + this.props.theme.palette.primary.main,
            borderRight  : '4px solid ' + this.props.theme.palette.primary.main,
            paddingLeft  : '20px'
          }}>
            <Grid container justify='center' direction='column' style={{minHeight: '10vh'}}>
              <Grid item>
                {this.props.steps != null ?
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb" style={{}}>
                    {this.props.steps.map((step, idx) => {
                      return (
                        step.onClick != null ?
                          <Link onClick={() => {
                            step.onClick()
                          }} key={idx}>
                            <Typography variant='h4' style={{color: this.props.theme.palette.primary.main}}>{step.name}</Typography>
                          </Link>
                          : <Typography variant='h4' key={idx}>{step.name}</Typography>);
                    })}
                  </Breadcrumbs>
                  : null}
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
    </div>;
  }
}

export default withTheme(Header);