import { KeycloakInstance } from 'keycloak-js';
import { ApiRequest, ApiResponse, TopicNotification, Topic } from '../api/Api';
import * as KeycloakAuthorization from 'keycloak-js/dist/keycloak-authz';
import { KeycloakAuthorizationInstance } from 'keycloak-js/dist/keycloak-authz'


export class WsConnectionManager {

  private endpoint: string;
  private readonly keycloak: KeycloakInstance;
  private wsChannel: WebSocket;
  private authorization: KeycloakAuthorizationInstance;

  private nextCallbackId = 0;
  private apiCallbacks: Map<number, (response: ApiResponse) => void> = new Map();

  private topicsRegistrations: Map<number, (notification: TopicNotification) => void> = new Map();

  //private nextRegistrationId = 0;

  public constructor(endpoint: string, keycloak: KeycloakInstance) {
    this.endpoint = endpoint;
    this.keycloak = keycloak;
  }

  public connect() {
    this.requestAuthorization((rpt) => {
      this.wsChannel = new WebSocket(this.endpoint);
      this.wsChannel.onopen = (event: Event) => {
        this.request({
          'action'    : 'security-update',
          'parameters': {
            'token': rpt,
            'type' : 'WebApp'
          }
        }, response => {
          if (response['status'] === 'ok') {
            this.onConnected();
          } else {
            console.error('Connection failed', response);
            this.onConnectionFailed();
          }
        });
        console.log('Connection with WebApp established', event);
      };
      this.wsChannel.onclose = (event: CloseEvent) => this.onCloseByServer(event);
      this.wsChannel.onerror = (event: Event) => this.onError(event);
      this.wsChannel.onmessage = (event: MessageEvent) => this.onMessage(event);
    });
  }

  public disconnect() {
    if(this.wsChannel.readyState === WebSocket.OPEN) {
      this.wsChannel.onclose = (event: CloseEvent) => {
        this.onDisconnected();
      };
      this.wsChannel.close(1000, 'Leaving willingly')
    }
  }

  //Called on successful connection
  public onConnected() {
    console.log('Connection completed');
  }

  //Called on failed connection
  public onConnectionFailed() {
    console.warn('Connection failed.');
  }

  //Called on close by server
  public onCloseByServer(event: CloseEvent) {
    console.log('Connection closed by server.', event);
  }

  //Called when disconnection requested & effective
  public onDisconnected() {
    console.log('Connection closed by server.');
  }

  //Called on error
  private onError(error: Event) {
    console.log('An error occurred', error);
  }


  private onMessage(message: MessageEvent) {
    //console.log('New message from WebApp', message);
    let msg = JSON.parse(message.data);

    if (msg.hasOwnProperty('responseId')) {

      //RESPONSE FORM SERVER
      let response = msg as ApiResponse;
      if (this.apiCallbacks.has(response.responseId)) {
        let callback = this.apiCallbacks.get(response.responseId);
        this.apiCallbacks.delete(response.responseId);
        callback(response);
      } else {
        console.warn('Callback not found', msg);
      }
    } else if (msg.hasOwnProperty('action')) {

      //REQUEST FORM SERVER
      let request = msg as ApiRequest;
      switch (request.action) {
        case 'topic-notification': {
          let parameters = request.parameters;
          let registrationId = parameters['registrationId'];
          if (this.topicsRegistrations.has(registrationId)) {
            let callback = this.topicsRegistrations.get(registrationId);
            callback(parameters);
          } else {
            console.warn('No callback found for registrationId ' + registrationId);
          }
        }
          break;
        default: {
          console.error('Cannot process message. Action not handled:', msg);
          let response: Partial<ApiResponse> = {
            status    : 'nok',
            responseId: request.requestId,
            comment   : 'Action unknown'
          };
          this.wsChannel.send(JSON.stringify(response));
        }
      }

    } else {
      console.error('Cannot process message', msg);
      let response: Partial<ApiResponse> = {
        status : 'nok',
        comment: 'parse failed'
      };
      this.wsChannel.send(JSON.stringify(response));
    }
  }

  public getConnectedUser(): any {
    return this.keycloak.userInfo;
  }

  public request(request: Partial<ApiRequest>, callback: (response: ApiResponse) => void) {
    //console.log('Token expires in ' + (this.keycloak.tokenParsed.exp - (new Date().getTime() / 1000)) + 'seconds');
    if (this.keycloak.isTokenExpired()) {
      this.updateToken(() => {
        let requestId = this.nextCallbackId;
        this.nextCallbackId++;
        request.requestId = requestId;
        this.apiCallbacks.set(request.requestId, callback);
        this.wsChannel.send(JSON.stringify(request));
      });
    } else {
      let requestId = this.nextCallbackId;
      this.nextCallbackId++;
      request.requestId = requestId;
      this.apiCallbacks.set(request.requestId, callback);
      this.wsChannel.send(JSON.stringify(request));
    }

  }

  private updateToken(cb: () => void) {
    this.keycloak.updateToken(1)
      .success((updated) => {
        //console.log('Token update successful', updated);
        if (updated) {
          //console.log('Requesting access update');
          this.requestAuthorization((rpt => {
            this.request({
              'action'    : 'security-update',
              'parameters': {
                'token': rpt,
                'type' : 'WebApp'
              }
            }, cb)
          }));
        }
      })
      .error((updated) => {
        console.error('Error occurred during token update', updated)
      });
  };

  private requestAuthorization(callback: (rpt: string) => void) {
    this.authorization = KeycloakAuthorization(this.keycloak);
    if (this.authorization.config != null) {
      this.authorization.entitlement(this.keycloak.clientId, {})
        .then(callback, () => {
          console.error('denied');
          callback(null)
        }, () => {
          console.error('err');
          callback(null)
        });
    } else {
      setTimeout(() => {
        this.requestAuthorization(callback)
      }, 20);
    }
  }

  public registerForTopic(topic: Topic, onRegistered: (registrationId: number) => void, onNotification: (notification: TopicNotification) => void) {

    let registrationRequest: Partial<ApiRequest> = {
      action    : 'topic-register',
      parameters: {
        topic: topic
      }
    };
    this.request(registrationRequest, response => {
      //console.log("Registration response: ", response);
      let result = response.result;
      let registrationId = result['registrationId'];
      this.topicsRegistrations.set(registrationId, onNotification);
      onRegistered(registrationId);
    });
  }

  public unregister(registrationId: number) {
    let registrationRequest: Partial<ApiRequest> = {
      action    : 'topic-unregister',
      parameters: {
        registrationId: registrationId
      }
    };
    this.request(registrationRequest, response => {
      //console.log("Unregistration response: ", response);
      if (this.topicsRegistrations.has(registrationId)) {
        this.topicsRegistrations.delete(registrationId);
      }
    });


  }
}
