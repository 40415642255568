import * as React from 'react';
import { Component } from 'react';
import { Typography } from '@material-ui/core';

interface HeadlineProps {
  text: string;
}

interface HeadlineState {
}

export default class Headline extends Component<HeadlineProps, HeadlineState> {

  constructor(props: HeadlineProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <div style={{display: 'flex', marginTop: '5vh', marginBottom: '3vh', flexGrow: 1}}>
      <span style={{display: 'inline-block', fontSize: '45px', marginRight: '15px'}}>
        <Typography variant='subtitle1'>{this.props.text}</Typography>
        </span>
      <div style={{
        display     : 'inline-block',
        flexGrow    : 1,
        borderBottom: '4px solid #3e86dd',
        margin      : 'auto',
        minHeight   : '2px',
        maxHeight   : '2px'
      }}/>
    </div>;
  }
}