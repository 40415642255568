import * as React from 'react';
import { Component } from 'react';
import { WithTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { WithTranslation } from 'react-i18next';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { ApiRequest, Language, Training } from '../api/Api';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';

interface CreateSessionDialogProps extends WithTranslation, WithTheme {
  wsManager: WsConnectionManager;
  lang: Language;
  training: Training;
  onClose: (canceled: boolean)=>void
}

interface CreateSessionDialogState {
  error?: string;
}

export default class CreateSessionDialog extends Component<CreateSessionDialogProps, CreateSessionDialogState> {

  private sessionNameInput: HTMLInputElement;

  constructor(props: CreateSessionDialogProps) {
    super(props);
    this.state = {
    };
  }

  private createSession() {

    if(this.sessionNameInput.value.trim() == '') {
      this.setState({error: 'ERR_03'});
      return;
    }

    let request : Partial<ApiRequest> = {
      action:'db-trainingSession-create',
      parameters: {
        "key": this.sessionNameInput.value.trim(),
        "creatorName": this.props.wsManager.getConnectedUser()['name'],
        "status": "CREATED",
        "trainingKey": this.props.training.key
      }
    };

    this.props.wsManager.request(request, response => {
      if(response.status != 'ok') {
        this.setState({
          error: response.errorCode
        })
      } else {
        this.props.onClose(false);
      }
    })

  }

  render() {

    return <Dialog open={true} onClose={()=>this.props.onClose(true)} aria-labelledby="form-dialog-title" PaperProps={{style: {border: '3px solid ' + this.props.theme.palette.primary.main}}}>
      <DialogTitle disableTypography >
          <Typography variant='h5' align='center' color={'primary'} style={{marginBottom: '15px'}}>{this.props.t('TrainingSessions.CreateSessionDialog.title')}</Typography>
          <div style={{
            flexGrow    : 1,
            borderBottom: '2px solid ' + this.props.theme.palette.primary.main,
            margin      : '0 10% 0px 10%',
            minHeight   : '1px',
            maxHeight   : '1px'
          }}/>
      </DialogTitle>
      <DialogContent>
        <FormControl error={this.state.error != null} fullWidth={true}>
          <InputLabel htmlFor="input">{this.props.t('TrainingSessions.CreateSessionDialog.inputLabel')}</InputLabel>
          <Input
            id="input"
            inputRef={(me)=>this.sessionNameInput=me}
          />
          <FormHelperText id="input-error-text" hidden={this.state.error == null}>{this.props.t('Errors.'+this.state.error)}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions style={{marginTop: '20px'}}>
        <Button onClick={()=>this.props.onClose(true)} variant='contained' color="primary">
          {this.props.t('Actions.cancel')}
        </Button>
        <Button onClick={()=>this.createSession()} variant='contained' color="default">
          {this.props.t('Actions.create')}
        </Button>
      </DialogActions>
    </Dialog>;
  }
}