import * as React from 'react';
import { Component } from 'react';
import { Grid, WithTheme, Dialog, Button, Tooltip, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { ApiRequest, Language, TopicNotification, Training, TrainingSession } from '../api/Api';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { WithTranslation } from 'react-i18next';

import HeadsetPanel from '../cmp/HeadsetPanel';
import HeadsetsList from '../cmp/HeadsetsList';

import RestartIcon from '@material-ui/icons/Refresh';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import StopIcon from '@material-ui/icons/StopRounded';
import AddIcon from '@material-ui/icons/AddBox';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import HeadsetCommands from '../misc/HeadsetCommands';

interface SessionPageProps extends WithTranslation, WithTheme {
  wsManager: WsConnectionManager;
  lang: Language;
  training: Training;
  session: TrainingSession;
  onSessionTerminated: () => void;
}

interface SessionPageState {
  headsetsInSession?: string[];
  showAvailableHeadsets: boolean;
  showConfirmTermination: boolean;
  showConfirmReset: boolean;
  paused: boolean;
}

export default class SessionPage extends Component<SessionPageProps, SessionPageState> {

  private topicRegistrationId: number;

  constructor(props: SessionPageProps) {
    super(props);
    this.state = {
      showAvailableHeadsets : false,
      showConfirmTermination: false,
      showConfirmReset      : false,
      paused                : false
    };
  }


  componentDidMount(): void {
    //register
    this.loadHeadsetsInSession();
  }

  componentWillUnmount(): void {
    if (this.topicRegistrationId != null) {
      this.props.wsManager.unregister(this.topicRegistrationId);
    }
  }

  private loadHeadsetsInSession() {
    let request: Partial<ApiRequest> = {
      action    : 'db-trainingSession-headsets-list',
      parameters: {
        trainingSessionKey: this.props.session.key
      }
    };

    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve headsets in session', response);
      } else {
        this.setState({headsetsInSession: response.result as string[]}, () => {
          if (this.topicRegistrationId == null) {
            this.props.wsManager.registerForTopic('sessions',
              (registrationId) => this.topicRegistrationId = registrationId,
              notification => this.notificationReceived(notification));
          }
        });
      }
    });
  }

  private terminateSession() {
    HeadsetCommands.dismissAll(
      this.props.wsManager,
      response => {
        if (response['status'] !== 'ok') {
          console.error('Could not dismiss all headsets', response);
        }
        let request: Partial<ApiRequest> = {
          action    : 'session-terminate',
          parameters: {
            trainingSessionKey: this.props.session.key
          }
        };
        this.props.wsManager.request(request, response1 => {
          if (response1.status !== 'ok') {
            console.error('Could not terminate the session', response);
          }
          this.setState({showConfirmTermination: false}, () => {
            this.props.onSessionTerminated();
          });
        });
      },
      this.props.session.key
    );
  }

  private resetAllHeadsets() {
    HeadsetCommands.resetAll(
      this.props.wsManager,
      response => {
        if (response['status'] !== 'ok') {
          console.error('Could not reset all headsets', response);
        }
        this.setState({showConfirmReset: false});
      },
      this.props.session.key
    );
  }

  private pauseAllHeadsets() {
    HeadsetCommands.pauseAll(
      this.props.wsManager,
      response => {
        this.setState({paused: true});
        if (response['status'] !== 'ok') {
          console.error('Could not pause all headsets', response);
        }
      },
      this.props.session.key
    );
  }

  private resumeAllHeadsets() {
    HeadsetCommands.resumeAll(
      this.props.wsManager,
      response => {
        this.setState({paused: false});
        if (response['status'] !== 'ok') {
          console.error('Could not resume all headsets', response);
        }
      },
      this.props.session.key
    );
  }

  private notificationReceived(notification: TopicNotification) {
    if (notification.details['key'] == this.props.session.key) {
      this.loadHeadsetsInSession();
    }
  }


  render() {
    let dialog;
    if (this.state.showAvailableHeadsets) {
      dialog =
        <Dialog key='dialog' open={this.state.showAvailableHeadsets} onClose={() => this.setState({showAvailableHeadsets: false})} maxWidth='lg'>
          <HeadsetsList {...this.props}/>
        </Dialog>
    } else if (this.state.showConfirmTermination) {
      dialog =
        <Dialog key='dialog' open={this.state.showConfirmTermination} onClose={() => this.setState({showConfirmTermination: false})} maxWidth='lg'>
          <DialogTitle>{this.props.t('SessionPage.ConfirmTermination.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.t('SessionPage.ConfirmTermination.text')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({showConfirmTermination: false})} color="primary">
              {this.props.t('Actions.cancel')}
            </Button>
            <Button onClick={() => this.terminateSession()} color="secondary" variant='contained' autoFocus>
              {this.props.t('Actions.terminate')}
            </Button>
          </DialogActions>
        </Dialog>
    } else if (this.state.showConfirmReset) {
      dialog =
        <Dialog key='dialog' open={this.state.showConfirmReset} onClose={() => this.setState({showConfirmReset: false})} maxWidth='lg'>
          <DialogTitle>{this.props.t('SessionPage.ConfirmReset.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.t('SessionPage.ConfirmReset.text')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({showConfirmReset: false})} color="primary">
              {this.props.t('Actions.cancel')}
            </Button>
            <Button onClick={() => this.resetAllHeadsets()} color="primary" variant='contained' autoFocus>
              {this.props.t('Actions.restart')}
            </Button>
          </DialogActions>
        </Dialog>
    }

    let pauseButton;
    if (this.state.paused) {
      pauseButton =
        <Tooltip title={this.props.t('SessionPage.resumeSessionTooltip')}>
          <Button fullWidth={true} variant='outlined' color='primary' disabled={this.state.headsetsInSession == null || this.state.headsetsInSession.length == 0} onClick={() => this.resumeAllHeadsets()} style={{display: 'flex'}}>
            <PlayIcon style={{marginRight: '15px'}}/>
            <span style={{flexGrow: 1, textAlign: 'center'}}>{this.props.t('SessionPage.resumeSession')}</span>
          </Button>
        </Tooltip>;
    } else {
      pauseButton =
        <Tooltip title={this.props.t('SessionPage.pauseSessionTooltip')}>
          <Button variant='outlined' color='secondary' disabled={this.state.headsetsInSession == null || this.state.headsetsInSession.length == 0} onClick={() => this.pauseAllHeadsets()} style={{display: 'flex'}}>
            <PauseIcon style={{marginRight: '15px'}}/>
            <span style={{flexGrow: 1, textAlign: 'center'}}>{this.props.t('SessionPage.pauseSession')}</span>
          </Button>
        </Tooltip>;
    }

    return [
      <Grid container key='mainGrid' direction='column' style={{
        minHeight: '20vh',
        border   : '2px solid ' + this.props.theme.palette.primary.main
      }}>
        <Grid item spacing={2} container direction='row-reverse' style={{padding: '20px'}}>
          <Grid item>
            <Tooltip title={this.props.t('SessionPage.resetSessionTooltip')}>
              <Button variant='contained' color='secondary' disabled={this.state.headsetsInSession == null || this.state.headsetsInSession.length == 0} onClick={() => this.setState({showConfirmReset: true})} style={{display: 'flex'}}>
                <RestartIcon style={{marginRight: '15px'}}/>
                <span style={{flexGrow: 1, textAlign: 'center'}}>{this.props.t('SessionPage.resetSession')}</span>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            {pauseButton}
          </Grid>
          <Grid item>
            <Tooltip title={this.props.t('SessionPage.terminateSessionTooltip')}>
              <Button variant='outlined' color='secondary' onClick={() => this.setState({showConfirmTermination: true})} style={{display: 'flex'}}>
                <StopIcon style={{marginRight: '15px'}}/>
                <span style={{flexGrow: 1, textAlign: 'center'}}>{this.props.t('SessionPage.terminateSession')}</span>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}/>
          <Grid item>
            <Tooltip title={this.props.t('SessionPage.addHeadsetTooltip')}>
              <Button variant='contained' color='primary' onClick={() => this.setState({showAvailableHeadsets: true})} style={{display: 'flex'}}>
                <AddIcon style={{marginRight: '15px'}}/>
                <span style={{flexGrow: 1, textAlign: 'center'}}>{this.props.t('SessionPage.addHeadset')}</span>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item container spacing={3} style={{padding: '20px'}}>
          {this.state.headsetsInSession != null ? this.state.headsetsInSession.map(headsetSerial => {
            return <Grid item key={headsetSerial}>
              <HeadsetPanel
                trainingKey={this.props.training.key}
                trainingSessionKey={this.props.session.key}
                serialNumber={headsetSerial} {...this.props}
              />
            </Grid>
          }) : null}
        </Grid>
      </Grid>,
      dialog
    ];
  }
}

