import * as React from 'react';
import * as ReactDOM from 'react-dom';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import './index.css';

import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import LandingPage from './pages/LandingPage';

//const translations = require('./misc/translations.json');

const theme = createMuiTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily  : 'Open Sans',
    allVariants : {
      color: '#7b838b'
    },
    h1          : {
      fontSize  : '3.5rem',
      fontWeight: 600
    },
    subtitle1   : {
      fontSize  : '4.5rem',
      fontWeight: 600
    },
    h3          : {
      fontSize  : '4.5rem',
      fontWeight: 400
    },
    subtitle2   : {
      fontSize  : '4rem',
      fontWeight: 600
    },
    h2          : {
      fontSize  : '3rem',
      fontWeight: 600
    },
    h4          : {
      fontSize  : '2.5rem',
      fontWeight: 400
    },
    body2       : {
      fontSize  : '2rem',
      fontWeight: 600
    },
    body1       : {
      fontSize  : '2rem',
      fontWeight: 400
    },
    h5          : {
      fontSize  : '1.5rem',
      fontWeight: 600
    },
    h6          : {
      fontSize  : '1.5rem',
      fontWeight: 400
    },
    caption     : {
      fontSize  : '1.5rem',
      fontWeight: 300,
    }
  },
  overrides : {
    MuiTab     : {
      root:{
        maxWidth: 'none'
      },
      labelIcon: {
        fontSize  : '2rem',
        fontWeight: 400
      }
    },
    MuiButton  : {
      root     : {
        fontSize     : '1.8rem',
        textTransform: 'none'
      },
      contained: {
        //border         : '1px solid white',
        backgroundColor: '#2560C5',
        color          : 'white',
        boxShadow      : 'none',
        paddingLeft    : '30px',
        paddingRight   : '30px'
      }
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#30AA28'
        }
      }
    },
    MuiTooltip : {
      tooltip: {
        fontSize: '1.5em'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.5em'
      }
    }
  },


  //VR Blue:    #3e86dd
  //VR Orange:  #DD543E
  //VR Green:   #30AA28

  palette: {
    primary  : {
      main        : '#3e86dd',
      light       : '#7ab5ff',
      dark        : '#005aab',
      contrastText: '#ffffff'
    },
    secondary: {
      main        : '#DD543E',
      light       : '#ff856a',
      dark        : '#a52015',
      contrastText: '#ffffff'
    },
    grey     : {
      '400': '#7C7E84'
    }
  }
});

fetch('ir/translations.json').then(result => result.json()).then(translations => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng        : 'fr',
      fallbackLng: 'fr',
      debug      : false,
      whitelist  : ['en', 'fr', 'de'],
      resources  : translations
    }).then(() => {

    ReactDOM.render(
      <MuiThemeProvider theme={theme}>
        <LandingPage/>
      </MuiThemeProvider>,
      document.getElementById('root')
    );
  });
});

//Grey background:            #7b838b;
//Blue Background (Primary):  #3e86dd;
//H2 font color               #3a4149;