import * as React from 'react';
import { Component } from 'react';
import { ChartConfiguration } from 'chart.js';
import ChartJs from './ChartJs';
import { WithTranslation } from 'react-i18next';
import { Paper, Typography, WithTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Language, Test, TestResult, TrainingModule, TrainingSession } from '../api/Api';
import MyPalette from '../misc/MyPalette';

interface SuccessPerQuestionChartProps extends WithTranslation, WithTheme {
  lang: Language;
  module: TrainingModule;
  tests?: Test[];
  results?: TestResult[];
  sessions?: TrainingSession[];
  colors: {success: string, failure: string};
}

interface SuccessPerQuestionChartState {
  options?: Partial<ChartConfiguration>;
}


export default class SuccessPerQuestionChart extends Component<SuccessPerQuestionChartProps, SuccessPerQuestionChartState> {

  private chart: ChartJs;

  private chartOptions: Partial<ChartConfiguration> = {
    type   : 'bar',
    data   : {},
    options: {
      legend     : {
        position: 'bottom'
      },
      responsive : true,
      aspectRatio: 3,
      scales     : {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
  };


  constructor(props: SuccessPerQuestionChartProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.updateData();
  }


  componentDidUpdate(prevProps: Readonly<SuccessPerQuestionChartProps>, prevState: Readonly<SuccessPerQuestionChartState>, snapshot?: any): void {
    //console.debug('Component did update', this.props.results, prevProps.results, this.props.results !== prevProps.results, this.props.results != prevProps.results)
    if (this.props.results !== prevProps.results || this.props.lang !== prevProps.lang) {
      this.updateData();
    }
  }


  private updateData() {

    if (this.props.module != null && this.props.results != null && this.props.sessions != null && this.props.tests != null) {
      let testsOfTheModule = this.props.tests.filter(test => test.moduleKey === this.props.module.key);
      let resultsOfTheModule = this.props.results.filter(result => result.moduleKey === this.props.module.key);

      let data = new Map<string, {success: number, failures: number}>();

      resultsOfTheModule.forEach( result => {
        result.results.forEach(response => {
         let test = testsOfTheModule.find(test => test.testKey === response.testKey);
         if(!data.has(test.testKey)) {
           data.set(test.testKey, {success: 0, failures: 0});
         }
         let testResults = data.get(test.testKey);
          test.answer === response.answer ? testResults.success++ : testResults.failures++;
        });
      });
      this.updateGraphDataset(data, testsOfTheModule);
    }
  }

  private updateGraphDataset(newData: Map<string, {success: number, failures: number}>, testsOfTheModule: Test[]) {
    let newOptions: ChartConfiguration = Object.create(this.chartOptions);
    newOptions.data.labels = [];
    newOptions.data.datasets = [];

    let successArray: number[] = [];
    let failuresArray: number[] = [];

    testsOfTheModule.sort((a, b) => a.key - b.key).forEach(test => {
      newOptions.data.labels.push(test.name[this.props.lang]);
      let stats = newData.get(test.testKey);
      successArray.push(stats.success);
      failuresArray.push(stats.failures);
    });
    newOptions.data.datasets.push({
      label          : this.props.t('Charts.passed'),
      backgroundColor: this.props.colors.success,
      stack          : "a",
      data           : successArray
    });
    newOptions.data.datasets.push({
      label          : this.props.t('Charts.failed'),
      backgroundColor: MyPalette.getSecondaryColor(0, 1),
      stack          : "a",
      data           : failuresArray
    });

    this.setState({
      options: newOptions
    });
  }

  render() {
    return <Paper style={{
      padding: '10px',
      border : '1px solid ' + this.props.theme.palette.primary.light
    }} square={true}>
      <Grid container direction='column'>
        <Grid item container spacing={3}>
          <Grid item>
            <Typography style={{marginBottom: '10px'}}>{this.props.module.name[this.props.lang] + ' - ' + this.props.t('Charts.SuccessPerQuestionChart.title')}</Typography>
          </Grid>
          <Grid style={{flexGrow: 1}}/>
          <Grid item>
            {/*
            <Select value='last6months'>
              <MenuItem value='last6months'>6 derniers mois</MenuItem>
            </Select>
            */}
          </Grid>
        </Grid>
        <Grid item>
          {this.state.options != null ? <ChartJs options={this.state.options} ref={(me) => this.chart = me}/> : null}
        </Grid>
      </Grid>
    </Paper>;
  }
}