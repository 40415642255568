import { Result, Test, TestResult, TrainingModule } from '../api/Api';


export default class DataHelper {

  public static getModuleTestsResultsRatio(tests: Test[], results: Result[]):{passed: number, failed: number} {
    let res = {passed: 0, failed: 0};
    results.forEach(result => {
      let test = tests.find(test => test.testKey === result.testKey);
      test.answer === result.answer ? res.passed++ : res.failed++;
    });
    return res;
  }

  public static isModulePassed(module: TrainingModule, tests: Test[], results: Result[]):boolean {
    let res = DataHelper.getModuleTestsResultsRatio(tests, results);
    return (res.passed / tests.length) * 100 >= module.successThreshold;
  }


}