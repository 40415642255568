import * as React from 'react';
import { Component } from 'react';
import { Button, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { KeycloakInstance } from 'keycloak-js';

import DropDownArrowIcon from '@material-ui/icons/ArrowDropDown';
import { WithTranslation } from 'react-i18next';

interface UserMenuProps extends WithTranslation {
  keycloak: KeycloakInstance;
}

interface UserMenuState {
  openPopup: boolean;
}

export default class UserMenu extends Component<UserMenuProps, UserMenuState> {

  private anchorRef: HTMLButtonElement;

  constructor(props: UserMenuProps) {
    super(props);
    this.state = {
      openPopup: false
    };
  }


  private logOut() {
    this.props.keycloak.logout();
    //window.location.replace('/');
  }


  render() {
    return <div>
      <Button aria-controls="user-menu" aria-haspopup="true"  ref={(me) => this.anchorRef=me} onClick={() => this.setState({openPopup: true})} style={{color: 'white'}}>
        {this.props.keycloak.profile.firstName + ' ' + this.props.keycloak.profile.lastName}
        <DropDownArrowIcon style={{marginLeft: '8px'}}/>
      </Button>
      <Menu
        id="user-menu"
        getContentAnchorEl={null}
        anchorEl={this.anchorRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={this.state.openPopup}
        onClose={() => this.setState({openPopup: false})}
      >
        <MenuItem onClick={() => this.logOut()}>
          <Typography variant='body1'>{this.props.t('Actions.disconnect')}</Typography>
        </MenuItem>
      </Menu>
    </div>;
  }
}