import * as React from 'react';
import { Component } from 'react';
import { Grid, Tooltip, WithTheme } from '@material-ui/core';
import { ApiRequest, Language, Training, TrainingSession } from '../api/Api';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import Typography from '@material-ui/core/Typography';
import { WithTranslation } from 'react-i18next';

import CreateIcon from '@material-ui/icons/PlaylistAdd';
import JoinIcon from '@material-ui/icons/PersonAdd';
import Tab from '@material-ui/core/Tab';
import CreateSessionDialog from './CreateSessionDialog';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';

interface TrainingSessionsProps extends WithTranslation, WithTheme {
  wsManager: WsConnectionManager;
  lang: Language;
  training: Training;
  onJoinSession: (session: TrainingSession) => void;
}

interface TrainingSessionsState {
  showCreateDialog: boolean;
  sessions: TrainingSession[];
  selectedSession?: TrainingSession;
}

export default class TrainingSessions extends Component<TrainingSessionsProps, TrainingSessionsState> {

  constructor(props: TrainingSessionsProps) {
    super(props);
    this.state = {
      showCreateDialog: false,
      sessions        : []

    };
  }

  componentDidMount(): void {
    this.updateSessionsList();
  }


  private updateSessionsList() {

    let request: Partial<ApiRequest> = {
      action    : 'db-trainingSessions-list',
      parameters: {
        'trainingKey': this.props.training.key
      }
    };

    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve training sessions', response);
      } else {
        this.setState({
          sessions: (response.result as TrainingSession[]).sort((a, b) => b.creationDate-a.creationDate)
        });
      }
    })

  }

  private createSession() {
    this.setState({showCreateDialog: true});
  }

  private joinSession() {
    this.props.onJoinSession(this.state.selectedSession);

  }

  private terminateSession() {

  }

  render() {

    let dialog;
    if (this.state.showCreateDialog) {
      dialog =
        <CreateSessionDialog key='createDialog' {...this.props} onClose={(canceled) => this.setState({showCreateDialog: false}, (canceled ? null : () => this.updateSessionsList()))}/>;
    }

    let sessionsTable;
    if (this.state.sessions.length == 0) {
      sessionsTable =
        <Typography variant={'subtitle1'} align='center'>{this.props.t('TrainingSessions.emptyTable')}</Typography>
    } else {
      sessionsTable = <Table>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell>{this.props.t('TrainingSessions.sessionName')}</TableCell>
            <TableCell>{this.props.t('TrainingSessions.sessionDate')}</TableCell>
            <TableCell>{this.props.t('TrainingSessions.sessionCreator')}</TableCell>
            <TableCell>{this.props.t('TrainingSessions.sessionStatus')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.sessions.map(session => (
            <TableRow key={session.key} onClick={() => {
              if (session.status !== 'TERMINATED') {
                this.setState({selectedSession: session})
              }
            }}>
              <TableCell padding="checkbox">
                <Checkbox
                  disabled={session.status === 'TERMINATED'}
                  onChange={(evt, value) => this.setState({selectedSession: (value ? session : null)})}
                  checked={this.state.selectedSession != null && this.state.selectedSession.key === session.key}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {session.key}
              </TableCell>
              <TableCell>{new Date(session.creationDate).toLocaleDateString()}</TableCell>
              <TableCell>{session.creatorName}</TableCell>
              <TableCell>{session.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>;
    }

    let controls = [
      <Tooltip key='create' title={this.props.t('TrainingSessions.createIconTooltip')}>
        <Tab
          label={this.props.t('Actions.create')}
          icon={<CreateIcon style={{width: '32px', height: '32px'}}/>}
          onClick={() => this.createSession()}
          style={{color: this.props.theme.palette.primary.main}}
        />
      </Tooltip>
    ];
    if (this.state.selectedSession != null) {
      controls.push(
        <Tooltip key='join' title={this.props.t('TrainingSessions.joinIconTooltip')}>
          <Tab
            label={this.props.t('Actions.join')}
            icon={<JoinIcon style={{width: '32px', height: '32px'}}/>}
            onClick={() => this.joinSession()}
            style={{color: this.props.theme.palette.primary.main}}
          />
        </Tooltip>);

      /*
      controls.push(
        <Tooltip key='terminate' title={this.props.t('TrainingSessions.terminateIconTooltip')}>
          <Tab
            label={this.props.t('Actions.terminate')}
            icon={<TerminateIcon style={{width: '32px', height: '32px'}}/>}
            onClick={() => this.terminateSession()}
            style={{color: this.props.theme.palette.primary.main}}
          />
        </Tooltip>);
        */

    }

    return [<Grid container direction='column' key='mainGrid'>
      <Grid item>
        <Typography variant='subtitle2' style={{
          marginTop   : '20px',
          marginBottom: '15px',
          color       : '#42454E'
        }}>{this.props.t('TrainingSessions.title')}</Typography>
      </Grid>
      <Grid item container style={{display: 'flex'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>{controls}</div>
        <div style={{
          display        : 'inline-block',
          minHeight      : '40vh',
          flexGrow       : 1,
          backgroundColor: this.props.theme.palette.background.paper
        }}>
          {sessionsTable}
        </div>
      </Grid>
    </Grid>,
      dialog
    ];
  }
}

