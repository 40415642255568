import * as React from 'react';
import { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { Language, Test, Training, TrainingModule } from '../api/Api';
import { WithTheme } from '@material-ui/core';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import MatriculesList from '../cmp/MatriculesList';
import MatriculeDetails from '../cmp/MatriculeDetails';
import { Moment } from 'moment';

interface MatriculesStatsProps extends WithTranslation, WithTheme {
  lang: Language;
  wsManager: WsConnectionManager;
  modules?: TrainingModule[];
  tests?: Test[];
  training: Training;
  from: Moment;
  to: Moment;
}

interface MatriculesStatsState {
    selectedMatricule?: any;
}

export default class MatriculesStats extends Component<MatriculesStatsProps, MatriculesStatsState> {


  constructor(props: MatriculesStatsProps) {
    super(props);
    this.state = {};
  }


  render() {

    let content;
    if(this.state.selectedMatricule == null) {
      content = <MatriculesList {...this.props} onMatriculeSelected={mat => this.setState({selectedMatricule: mat})}/>;
    } else {
      content = <MatriculeDetails {...this.props} matricule={this.state.selectedMatricule} onClose={()=>this.setState({selectedMatricule: null})}/>;
    }

    return content;

  }
}