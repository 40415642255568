import * as React from 'react';
import { Component, CSSProperties } from 'react';
import Chart, { ChartConfiguration } from 'chart.js';

interface ChartJsProps {
  options: ChartConfiguration;
}

interface ChartJsState {
}

export default class ChartJs extends Component<ChartJsProps, ChartJsState> {

  private container: HTMLDivElement;
  private canvas: HTMLCanvasElement;
  private chart: Chart;

  constructor(props: ChartJsProps) {
    super(props);
  }

  componentDidMount(): void {
    if (this.props.options != null) {
      if(this.props.options.type == 'horizontalBar') {
        this.container.style.height = (30 * this.props.options.data.labels.length) + 'px';
      }
      this.chart = new Chart(this.canvas, this.props.options);
    }
  }

  componentWillUnmount(): void {
    if (this.chart != null) {
      this.chart.clear();
      this.chart.destroy();
      this.chart = null;
    }
  }


  componentDidUpdate(prevProps: Readonly<ChartJsProps>, prevState: Readonly<ChartJsState>, snapshot?: any): void {
    //console.debug('Component did update', this.props.options, prevProps.options, this.props.options !== prevProps.options, this.props.options != prevProps.options)

    if (this.props.options !== prevProps.options) {

      if (this.chart != null) {
        if (this.props.options != null) {
          this.chart.config = this.props.options;
          if(this.props.options.type == 'horizontalBar') {
            this.container.style.height = (30 + (30 * this.props.options.data.labels.length)) + 'px';
          }
          this.chart.update();
          this.chart.resize();
        } else {
          this.chart.clear();
          this.chart.destroy();
          this.chart = null;
        }
      } else if (this.props.options != null) {
        this.chart = new Chart(this.canvas, this.props.options);
      }
    }
  }

  render() {
    return <div ref={(me) => this.container = me} style={{position: 'relative', width: '98%'}}>
      <canvas ref={(me) => this.canvas = me}/>
    </div>;
  }
}