import * as React from 'react';
import { Component } from 'react';
import { Language, Test, TestResult, TrainingModule, TrainingSession } from '../api/Api';
import { WithTranslation } from 'react-i18next';
import { Paper, Typography, WithTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ChartConfiguration } from 'chart.js';
import ChartJs from './ChartJs';

interface ModuleSuccessGaugeProps extends WithTranslation, WithTheme {
  lang: Language;
  module?: TrainingModule;
  tests?: Test[];
  results?: TestResult[];
  sessions?: TrainingSession[];
  colors: {success: string, failure: string}
}

interface ModuleSuccessGaugeState {
  options?: Partial<ChartConfiguration>;
  success?: number;
  failures?: number;
}

export default class ModuleSuccessGauge extends Component<ModuleSuccessGaugeProps, ModuleSuccessGaugeState> {

  private chart: ChartJs;
  private chartOptions: Partial<ChartConfiguration> = {
    type   : 'doughnut',
    data   : {
      datasets: []
    },
    options: {
      cutoutPercentage: 65,
      rotation     : -Math.PI/2,
      responsive   : true,
      legend       : {
        display: true,
        position: 'bottom',
        fullWidth: true
      },
      animation    : {
        animateScale : true,
        animateRotate: true
      }
    }
  };

  constructor(props: ModuleSuccessGaugeProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.updateData();
  }

  componentDidUpdate(prevProps: Readonly<ModuleSuccessGaugeProps>, prevState: Readonly<ModuleSuccessGaugeState>, snapshot?: any): void {
    //console.debug('Component did update', this.props.results, prevProps.results, this.props.results !== prevProps.results, this.props.results != prevProps.results)
    if (this.props.results !== prevProps.results || this.props.lang !== prevProps.lang) {
      this.updateData();
    }
  }

  private updateData() {
    if (this.props.module != null && this.props.results != null && this.props.sessions != null && this.props.tests != null) {
      let testsOfTheModule = this.props.tests.filter(test => test.moduleKey === this.props.module.key);
      let resultsOfTheModule = this.props.results.filter(result => result.moduleKey === this.props.module.key);
      let nbSuccess = 0;
      let nbFail = 0;
      this.props.sessions.forEach(session => {
        let resultsOfModuleInSession = resultsOfTheModule.filter(result => result.trainingSessionKey === session.key);
        let nbModuleValidatedInSession = 0;
        let nbModuleFailedInSession = 0;
        resultsOfModuleInSession.forEach(runResult => {
          let nbTestsPassed = 0;
          let nbTestsFailed = 0;
          runResult.results.forEach(res => {
            let test = testsOfTheModule.find(test => test.testKey === res.testKey);
            test.answer === res.answer ? nbTestsPassed++ : nbTestsFailed++;
          });
          if ((nbTestsPassed / testsOfTheModule.length) * 100 >= this.props.module.successThreshold) {
            nbModuleValidatedInSession++;
          } else {
            nbModuleFailedInSession++;
          }
        });
        nbSuccess += nbModuleValidatedInSession;
        nbFail += nbModuleFailedInSession;
      });
      this.updateGraphDataset(nbSuccess, nbFail);
    }
  }

  private updateGraphDataset(success: number, failures: number) {
    let newOptions: ChartConfiguration = Object.create(this.chartOptions);
    newOptions.data.labels = [this.props.module.name[this.props.lang] + ' ' + this.props.t('Charts.passed'), this.props.module.name[this.props.lang] + ' ' + this.props.t('Charts.failed')];
    newOptions.data.datasets = [{
      label          : this.props.module.key,
      data           : [success, failures],
      backgroundColor: [this.props.colors.success, this.props.colors.failure]
    }];
    this.setState({
      options : newOptions,
      success : success,
      failures: failures
    });
  }

  render() {

    return <Paper style={{
      padding    : '10px',
      //marginTop  : '16px',
      //marginRight: '16px',
      //width: '18vw',
      border     : '1px solid ' + this.props.theme.palette.primary.light
    }} square={true}>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography>{this.props.module.name[this.props.lang]}</Typography>
        </Grid>
        <Grid item>
          {this.state.options != null ? <ChartJs options={this.state.options} ref={(me) => this.chart = me}/> : null}
        </Grid>
        <Grid item >
          <Typography style={{fontSize: '16px', fontWeight: 400}}>{this.props.t('Charts.ModuleSuccessGauge.numberOfSessions')}: {this.state.success != null ? (this.state.success + this.state.failures) : '-'}</Typography>
        </Grid>
      </Grid>
    </Paper>;
  }
}