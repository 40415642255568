import * as React from 'react';
import { Component } from 'react';
import MyPalette from '../misc/MyPalette';
import { Button, Typography } from '@material-ui/core';
import * as moment from 'moment';
import { Language, Test, TrainingModule } from '../api/Api';
import { WithTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface TestsListProps extends WithTranslation{
  lang: Language;
  matricule: any;
  onTestSelected: (test: any) => void;
  modules?: TrainingModule[];
  tests?: Test[];
}

interface TestsListState {
  hoveredLine?: number;
}

export default class TestsList extends Component<TestsListProps, TestsListState> {

  constructor(props: TestsListProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <table style={{width: '100%', borderCollapse: 'separate', borderSpacing: '0 10px'}}>
      <thead>
      <tr>
        <td><Typography variant={'body1'}>{this.props.t('Tests.date')}</Typography></td>
        <td><Typography variant={'body1'}>{this.props.t('Matricules.scenario')}</Typography></td>
        <td><Typography variant={'body1'}>{this.props.t('Matricules.result')}</Typography></td>
        <td><Typography variant={'body1'}>{this.props.t('Tests.errors')}</Typography></td>
        <td><Typography variant={'body1'}>{this.props.t('Tests.score')}</Typography></td>
      </tr>
      </thead>
      <tbody>
      {this.props.matricule.records.map((test: any) => {

        let moduleName = test.moduleId;
        let module = this.props.modules.find(m => m.key === test.moduleId);
        if(module != null) {
          moduleName = module.name[this.props.lang];
        }

        let nbFault;
        let score;

        /*if (module.type == 'Tutorial') {
          nbFault = '-';
          score = test.scoreClamped + '/100';
        } else */if (module.type == 'Checklist') {
          nbFault = (test.logs != null && test.logs.length != 0 ? test.logs.filter((t: any) => t.answerCorrect != t.answerUser).length : 0);
          score = test.scoreClamped + '/100';
        } else {
          nbFault = (test.logs != null ? test.logs.length : 0);
          score = test.scoreClamped + '/100';
        }

        let style : CSSProperties = {
          color          : 'white',
          backgroundColor: test.formateurValidation ? '#30AA28' : '#DD543E',
          cursor: 'pointer'
        };

        if(this.state.hoveredLine != null && this.state.hoveredLine === test.runId) {
          style.backgroundColor = test.formateurValidation ? '#177811' : '#CB341C';
        }

        return <tr
          key={test.runId}
          style={style}
          onClick={() => this.props.onTestSelected(test)}
          onMouseEnter={e=>this.setState({hoveredLine: test.runId})}
          onMouseLeave={e=>this.setState({hoveredLine: null})}
        >
          <td style={{
            marginTop   : '10px',
            marginBottom: '10px', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px'
          }}>
            <Typography variant={'body1'} color='inherit'>{moment(test.lastModified).format('DD/MM/YYYY')}</Typography>
          </td>
          <td>
            <Typography variant={'body1'} color='inherit'>{moduleName}</Typography>
          </td>
          <td><Typography variant={'body1'} color='inherit'>{test.formateurValidation ? this.props.t('Charts.passed') : this.props.t('Charts.failed')}</Typography></td>
          <td><Typography variant={'body1'} color='inherit'>{nbFault}</Typography></td>
          <td><Typography variant={'body1'} color='inherit'>{score}</Typography></td>
          {/*<td style={{paddingRight: '20px', whiteSpace: 'nowrap'}}>
            <Button size={'small'} variant={'outlined'} color={'inherit'} onClick={()=>this.props.onTestSelected(test)}>Détail des fautes</Button>
          </td>*/}
        </tr>
      })}
      </tbody>
    </table>;
  }
}