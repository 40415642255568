import * as React from 'react';
import { Component } from 'react';
import { ChartConfiguration } from 'chart.js';
import ChartJs from './ChartJs';
import { WithTranslation } from 'react-i18next';
import { Paper, Typography, WithTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Language, Test, TestResult, TrainingModule, TrainingSession } from '../api/Api';
import MyPalette from '../misc/MyPalette';

interface SuccessPerQuestionRadarProps extends WithTranslation, WithTheme {
  lang: Language;
  module: TrainingModule;
  tests?: Test[];
  results?: TestResult[];
  sessions?: TrainingSession[];
  colors: { success: string, failure: string };
}

interface SuccessPerQuestionRadarState {
  options?: Partial<ChartConfiguration>;
}


export default class SuccessPerQuestionRadar extends Component<SuccessPerQuestionRadarProps, SuccessPerQuestionRadarState> {

  private chart: ChartJs;

  private chartOptions: Partial<ChartConfiguration> = {
    type   : 'radar',
    data   : {},
    options: {
      legend: {
        display: false,
        position: 'top'
      },
      scale: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        }
      } as any
    }
  };


  constructor(props: SuccessPerQuestionRadarProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.updateData();
  }


  componentDidUpdate(prevProps: Readonly<SuccessPerQuestionRadarProps>, prevState: Readonly<SuccessPerQuestionRadarState>, snapshot?: any): void {
    //console.debug('Component did update', this.props.results, prevProps.results, this.props.results !== prevProps.results, this.props.results != prevProps.results)
    if (this.props.results !== prevProps.results || this.props.lang !== prevProps.lang) {
      this.updateData();
    }
  }


  private updateData() {

    if (this.props.module != null && this.props.results != null && this.props.sessions != null && this.props.tests != null) {
      let testsOfTheModule = this.props.tests.filter(test => test.moduleKey === this.props.module.key);
      let resultsOfTheModule = this.props.results.filter(result => result.moduleKey === this.props.module.key);

      let data = new Map<string, { success: number, failures: number }>();

      resultsOfTheModule.forEach(result => {
        result.results.forEach(response => {
          let test = testsOfTheModule.find(test => test.testKey === response.testKey);
          if (!data.has(test.testKey)) {
            data.set(test.testKey, {success: 0, failures: 0});
          }
          let testResults = data.get(test.testKey);
          test.answer === response.answer ? testResults.success++ : testResults.failures++;
        });
      });
      this.updateGraphDataset(data, testsOfTheModule);
    }
  }

  private updateGraphDataset(newData: Map<string, { success: number, failures: number }>, testsOfTheModule: Test[]) {
    let newOptions: ChartConfiguration = Object.create(this.chartOptions);
    newOptions.data.labels = [];
    newOptions.data.datasets = [];

    let successArray: number[] = [];

    testsOfTheModule.sort((a, b) => a.key - b.key).forEach(test => {
      newOptions.data.labels.push(test.name[this.props.lang]);
      let stats = newData.get(test.testKey);
      successArray.push(stats.success);
    });

    (newOptions.options.scale as any).ticks.stepSize = Math.trunc(Math.max(...successArray) / 5);
    newOptions.data.datasets.push({
      label               : this.props.t('Charts.passed'),
      borderColor         : this.props.colors.success,
      backgroundColor     : this.props.colors.success,
      pointBackgroundColor: this.props.colors.success,
      fill: false,
      data                : successArray
    });

    this.setState({
      options: newOptions
    });
  }

  render() {
    return <Paper style={{
      padding: '10px',
      border : '1px solid ' + this.props.theme.palette.primary.light
    }} square={true}>
      <Grid container direction='column'>
        <Grid item container spacing={3}>
          <Grid item>
            <Typography style={{marginBottom: '10px'}}>{this.props.module.name[this.props.lang] + ' - ' + this.props.t('Charts.SuccessPerQuestionRadar.title')}</Typography>
          </Grid>
          <Grid style={{flexGrow: 1}}/>
          <Grid item>
            {/*
            <Select value='last6months'>
              <MenuItem value='last6months'>6 derniers mois</MenuItem>
            </Select>
            */}
          </Grid>
        </Grid>
        <Grid item>
          {this.state.options != null ? <ChartJs options={this.state.options} ref={(me) => this.chart = me}/> : null}
        </Grid>
      </Grid>
    </Paper>;
  }
}