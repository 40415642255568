import * as React from 'react';
import { Component } from 'react';
import { ChartConfiguration } from 'chart.js';
import ChartJs from './ChartJs';
import { WithTranslation } from 'react-i18next';
import { Button, Paper, Switch, Typography, WithTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Language, OrganisationalUnit, OrganisationalUnitType, Test, TestResult, Training, TrainingModule, TrainingSession } from '../api/Api';
import MyPalette from '../misc/MyPalette';

interface ModulesPerSessionChartProps extends WithTranslation, WithTheme {
  training: Training;
  orgUnitTypes: OrganisationalUnitType[];
  orgUnits?: OrganisationalUnit[];
  lang: Language;
  modules?: TrainingModule[];
  tests?: Test[];
  results?: TestResult[];
  sessions?: TrainingSession[];
}

interface ModulesPerSessionChartState {
  options?: Partial<ChartConfiguration>;
  valuesDisplay: 'abs' | 'percent';
}

interface TmpData {
  modulesData?: {
    moduleName: string;
    success: number[];
    failures: number[];
  }[],
  sessionsData: {
    sessionNames: string[];
  }

}

export default class ModulesPerSessionChart extends Component<ModulesPerSessionChartProps, ModulesPerSessionChartState> {

  private chart: ChartJs;

  private chartOptions: Partial<ChartConfiguration> = {
    type   : 'bar',
    data   : {},
    options: {
      legend     : {
        position: 'bottom'
      },
      responsive : true,
      aspectRatio: 3,
      scales     : {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
  };


  constructor(props: ModulesPerSessionChartProps) {
    super(props);
    this.state = {
      valuesDisplay: 'abs'
    };
  }

  componentDidMount(): void {
    this.updateData();
  }


  componentDidUpdate(prevProps: Readonly<ModulesPerSessionChartProps>, prevState: Readonly<ModulesPerSessionChartState>, snapshot?: any): void {
    //console.debug('Component did update', this.props.results, prevProps.results, this.props.results !== prevProps.results, this.props.results != prevProps.results)
    if (this.props.results !== prevProps.results || this.props.lang !== prevProps.lang) {
      this.updateData();
    }
  }


  private updateData() {
    if (this.props.modules != null && this.props.results != null && this.props.sessions != null && this.props.tests != null) {
      let tmpData: TmpData = {
        modulesData : [],
        sessionsData: {sessionNames: []}
      };
      this.props.modules.forEach((module, moduleIdx) => {
        let testsOfTheModule = this.props.tests.filter(test => test.moduleKey === module.key);
        let resultsOfTheModule = this.props.results.filter(result => result.moduleKey === module.key);
        let moduleTmpData: any = {moduleName: module.name[this.props.lang], success: [], failures: []};
        this.props.sessions.sort((a, b) => a.creationDate - b.creationDate).forEach(session => {
          if (moduleIdx === 0) {
            tmpData.sessionsData.sessionNames.push(session.key + '\n' + (new Date(session.creationDate)).toLocaleDateString());
          }
          let resultsOfModuleInSession = resultsOfTheModule.filter(result => result.trainingSessionKey === session.key);
          let nbModuleValidatedInSession = 0;
          let nbModuleFailedInSession = 0;
          resultsOfModuleInSession.forEach(runResult => {
            let nbTestsPassed = 0;
            let nbTestsFailed = 0;
            runResult.results.forEach(res => {
              let test = testsOfTheModule.find(test => test.testKey === res.testKey);
              test.answer === res.answer ? nbTestsPassed++ : nbTestsFailed++;
            });
            if ((nbTestsPassed / testsOfTheModule.length) * 100 >= module.successThreshold) {
              nbModuleValidatedInSession++;
            } else {
              nbModuleFailedInSession++;
            }
          });

          if (this.state.valuesDisplay == 'percent') {
            moduleTmpData.success.push(Math.round((nbModuleValidatedInSession * 100) / (nbModuleValidatedInSession + nbModuleFailedInSession)));
            moduleTmpData.failures.push(Math.round((nbModuleFailedInSession * 100) / (nbModuleValidatedInSession + nbModuleFailedInSession)));
          } else {
            moduleTmpData.success.push(nbModuleValidatedInSession);
            moduleTmpData.failures.push(nbModuleFailedInSession);
          }
        });
        tmpData.modulesData.push(moduleTmpData);
      });
      this.updateGraphDataset(tmpData);
    }
  }

  private updateGraphDataset(newData: TmpData) {


    let newOptions: ChartConfiguration = Object.create(this.chartOptions);
    newOptions.data.labels = newData.sessionsData.sessionNames;
    newOptions.data.datasets = [];
    newData.modulesData.forEach((moduleData, idx) => {

      newOptions.data.datasets.push({
        label          : moduleData.moduleName + ' ' + this.props.t('Charts.passed'),
        backgroundColor: MyPalette.getSuccessColor(idx, newData.modulesData.length),
        stack          : moduleData.moduleName,
        data           : moduleData.success
      });
      newOptions.data.datasets.push({
        label          : moduleData.moduleName + ' ' + this.props.t('Charts.failed'),
        backgroundColor: MyPalette.getSecondaryColor(idx, newData.modulesData.length),
        stack          : moduleData.moduleName,
        data           : moduleData.failures
      });
    });
    this.setState({
      options: newOptions
    });
  }


  render() {
    return <Paper style={{
      padding: '10px',
      border : '1px solid ' + this.props.theme.palette.primary.light
    }} square={true}>
      <Grid container direction='column' spacing={3}>
        <Grid item container>
          <Grid item container xs={6}>
            <Grid item>
              <Typography>{this.props.t('Charts.ModulesPerSession.title')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} container direction={'row-reverse'} spacing={3}>
            <Grid item>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>{this.props.t('Charts.quantity')}</Grid>
                  <Grid item>
                    <Switch
                      size='small'
                      checked={this.state.valuesDisplay == 'percent'}
                      onChange={evt => this.setState({valuesDisplay: evt.target.checked ? 'percent' : 'abs'}, () => this.updateData())}
                      value="checkedC"
                    />
                  </Grid>
                  <Grid item>%</Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {this.state.options != null ? <ChartJs options={this.state.options} ref={(me) => this.chart = me}/> : null}
        </Grid>
      </Grid>
    </Paper>;
  }
}