import * as React from 'react';
import { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import * as moment from 'moment';
import MyPalette from '../misc/MyPalette';
import { Language, Test, TrainingModule } from '../api/Api';
import { WithTranslation } from 'react-i18next';

interface TestDetailsProps extends WithTranslation {
  lang: Language;
  matricule: any;
  testSelected: any;
  onClose: () => void;
  modules?: TrainingModule[];
  tests?: Test[];
}

interface TestDetailsState {
}

export default class TestDetails extends Component<TestDetailsProps, TestDetailsState> {

  constructor(props: TestDetailsProps) {
    super(props);
    this.state = {};
  }

  render() {

    let moduleName = this.props.testSelected.moduleId;
    let module = this.props.modules.find(m => m.key === this.props.testSelected.moduleId);
    if (module != null) {
      moduleName = module.name[this.props.lang];
    }

    let nbFault;
    let score;
    let panels: any[] = [];

    /*
    if (module.type == 'Tutorial') {

      nbFault = '-';
      score = this.props.testSelected.scoreClamped + '/100';


    } else */if (module.type == 'Checklist') {

      nbFault = (this.props.testSelected.logs != null && this.props.testSelected.logs.length != 0 ? this.props.testSelected.logs.filter((t: any) => t.answerCorrect != t.answerUser).length : 0);
      score = this.props.testSelected.scoreClamped + '/100';

      if (this.props.testSelected.logs != null) {
        //{id: 4, timestamp: 1574350217, answerCorrect: "EChecklistAnswerType_YES", answerUser: "EChecklistAnswerType_NO"}
        this.props.testSelected.logs.filter((t: any) => t.answerCorrect != t.answerUser).forEach((log: any) => {

          let test = this.props.tests.find(tt => tt.key === log.testkey);
          let testName = log.id;
          if (test != null) {
            testName = test.name[this.props.lang];
          }

          panels.push(<Grid item key={log.id}>
            <table style={{width: '100%', borderCollapse: 'collapse', border: '1px solid grey', color: 'white'}}>
              <tbody>
              <tr style={{backgroundColor: 'grey'}}>
                <td style={{width: '10%', paddingLeft: '5px', whiteSpace: 'nowrap'}}>
                  <Typography variant='h6' color='inherit'>{moment(log.timestamp * 1000).format('DD/MM/YYYY H:mm')}</Typography>
                </td>
                <td style={{textAlign: 'center', whiteSpace: 'nowrap'}}>
                  <Typography variant='h6' color='inherit'>{testName}</Typography></td>
                <td style={{width: '10%', textAlign: 'right', paddingRight: '5px', whiteSpace: 'nowrap'}}>
                  <Typography variant='h6' color='inherit'>Faute mineure: -5</Typography></td>
              </tr>
              <tr>
                <td colSpan={3} style={{border: '1px solid grey', textAlign: 'center'}}>
                  <Typography variant='h6'>{this.props.t('Tests.expectedValue') + test.options.filter(opt=>opt.value == log.answerCorrect)[0].name[this.props.lang]}</Typography>
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{
                  border   : '1px solid grey',
                  textAlign: 'center',
                  color    : MyPalette.getSecondaryColor(0, 1)
                }}><Typography variant='h6' color='inherit'>
                  {this.props.t('Tests.providedValue') + test.options.filter(opt=>opt.value == log.answerUser)[0].name[this.props.lang]}
                </Typography></td>
              </tr>
              </tbody>
            </table>
          </Grid>);
        });
      }

    } else {
      nbFault = (this.props.testSelected.logs != null ? this.props.testSelected.logs.length : 0);
      score = this.props.testSelected.scoreClamped + '/100';

      if (this.props.testSelected.logs != null) {
        //id: 0, codeName: "S03_O01_C-S_Phone Call", timestamp: 1574770784, faultType: "EFaultType_FAULT", comment: "La validation par téléphone pour signaler un passa…oche venant du four électrique n'a pas été reçue."
        this.props.testSelected.logs.forEach((log: any) => {

          let test = this.props.tests.find(tt => tt.key === log.testkey);
          let testName = log.testKey;
          if (test != null) {
            testName = test.name[this.props.lang];
          }

          panels.push(<Grid item key={log.id}>
            <table style={{width: '100%', borderCollapse: 'collapse', border: '1px solid grey', color: 'white'}}>
              <tbody>
              <tr style={{backgroundColor: 'grey'}}>
                <td style={{width: '10%', paddingLeft: '5px', whiteSpace: 'nowrap'}}>
                  <Typography variant='h6' color='inherit'>{moment(log.timestamp * 1000).format('DD/MM/YYYY H:mm')}</Typography>
                </td>
                <td style={{textAlign: 'center', whiteSpace: 'nowrap'}}>
                  <Typography variant='h6' color='inherit'>{testName}</Typography></td>
                <td style={{width: '10%', textAlign: 'right', paddingRight: '5px', whiteSpace: 'nowrap'}}>
                  <Typography variant='h6' color='inherit'>{(log.faultType == 'EFaultType_FAULT' ? this.props.t('Matricules.minorMistake') + ': -5' : this.props.t('Matricules.majorMistake') + ': -100')}</Typography>
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{border: '1px solid grey', textAlign: 'left', padding: '30px'}}>
                  <Typography variant='h6'>{log.comment}</Typography></td>
              </tr>
              </tbody>
            </table>
          </Grid>);
        });
      }

    }

    let playTime = moment.utc(Number(this.props.testSelected.playTime.substring(0, this.props.testSelected.playTime.lastIndexOf('.'))) * 1000);


    return <Grid container direction='column' spacing={2}>
      <Grid item key='header'>
        <table style={{width: '100%', borderCollapse: 'collapse', color: 'white', marginTop: '20px'}}>
          <tbody>
          <tr>
            <td style={{
              textAlign      : 'center',
              whiteSpace     : 'nowrap',
              minHeight      : '48px',
              height         : '48px',
              backgroundColor: this.props.testSelected.formateurValidation ? '#30AA28' : '#DD543E'
            }}>
              <Typography variant='h5' color='inherit'>{moduleName}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Grid container justify={'space-between'}>
                <Grid item sm={4} container direction='column'>
                  <Grid item><Typography variant='h6'>{this.props.t('Tests.date')}: {moment(this.props.testSelected.lastModified).format('DD/MM/YYYY')}</Typography></Grid>
                  <Grid item><Typography variant='h6'>{nbFault} {this.props.t('Tests.errors')}</Typography></Grid>
                  <Grid item>
                  <span style={{color: this.props.testSelected.formateurValidation ? MyPalette.getSuccessColor(0, 1) : MyPalette.getSecondaryColor(0, 1)}}>
                    <Typography variant='h6' color='inherit'>{this.props.t('Tests.score')}: {score}</Typography>
                  </span>
                  </Grid>
                </Grid>
                <Grid item sm={4} container direction='column' alignItems={'center'}>
                  <Grid item><Typography variant='h6'>{this.props.t('Matricules.matricule')} {this.props.matricule._id}</Typography></Grid>
                  <Grid item>
                  <span style={{color: this.props.testSelected.formateurValidation ? MyPalette.getSuccessColor(0, 1) : MyPalette.getSecondaryColor(0, 1)}}>
                    <Typography variant='h6' color='inherit'>{this.props.t('Matricules.scenario')} {(this.props.testSelected.formateurValidation ? this.props.t('Charts.passed') : this.props.t('Charts.failed'))}</Typography>
                  </span>
                  </Grid>
                  <Grid item><Typography variant='h6'>{this.props.t('Matricules.trainer')} {this.props.testSelected.formateurMatricule}</Typography></Grid>
                </Grid>
                <Grid item sm={4} container direction='column'>
                  <Grid item><Typography variant='h6' style={{textAlign: 'right'}}>
                    {this.props.t('Matricules.playTime')}: {playTime.format('H:mm:ss')}</Typography></Grid>
                </Grid>
              </Grid>
            </td>
          </tr>
          </tbody>
        </table>

      </Grid>
      {panels}
    </Grid>;
  }
}