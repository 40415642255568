






export default class DeferCounter {
  private _nb_down: number;
  private _counter: number;
  private _end: () => void;

  constructor(nb: number) {
    this._counter = nb;
    this._nb_down = 0;
  }

  public count(): void {
    this._nb_down++;
    if (this._nb_down == this._counter) {
      if (this._end != null) {
        this._end();
      }
    }
  }

  public getCount(): number {
    return this._nb_down;
  }

  public then(callback: () => void): void {
    this._end = callback;
    if (this._nb_down == this._counter) {
      if (callback != null) {
        callback();
      }
    }
  }
}