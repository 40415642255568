import * as React from 'react';
import { Component } from 'react';
import { Grid } from '@material-ui/core';
import Headline from '../cmp/Headline';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { ApiRequest, Language, Training } from '../api/Api';
import TrainingCard from '../training/TrainingCard';
import { withTranslation, WithTranslation } from 'react-i18next';

interface HomePageProps extends WithTranslation {
  wsManager: WsConnectionManager;
  lang: Language;
  onTrainingSelected: (training: Training) => void;
}

interface HomePageState {
  trainings: Training[];
}

class HomePage extends Component<HomePageProps, HomePageState> {

  constructor(props: HomePageProps) {
    super(props);
    this.state = {
      trainings: []
    };
  }

  componentDidMount(): void {
    this.loadTrainings();
  }

  private loadTrainings() {
    let request = {'action': 'db-trainings-list'} as ApiRequest;
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve trainings', response);
      } else {
        this.setState({trainings: (response.result as Training[]).sort((t1, t2) => t1.key.localeCompare(t2.key))});
      }
    });
  }

  render() {

    return <Grid container direction='column' style={{paddingBottom: '5vh'}}>
      <Grid item>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100vw'}}>
        <img src={'ir/ClientHeader.png'} style={{width: '100vw'}}/>
        <img src={'ir/ClientLogo.png'} style={{padding: '25px', alignSelf: 'center',position: 'absolute', width: '30vw', margin: 'auto', backgroundColor: 'rgb(255,255,255,0.8)'}}/>
        </div>
      </Grid>
      <Grid item container direction='column' style={{width: '90vw', margin: 'auto'}}>
        <Grid item>
          <Headline text={this.props.t('HomePage.headline')} />
        </Grid>
        <Grid item container>
          {this.state.trainings.map(training => {
            return <Grid item sm={4} key={training.key}>
              <TrainingCard training={training} {...this.props} onClick={()=>this.props.onTrainingSelected(training)}/>
            </Grid>;
          })}
        </Grid>
      </Grid>
    </Grid>;
  }
}

export default withTranslation()(HomePage);
