export default class MyPalette {
  /*
  public static primary: string[] = ['#3e86dd', '#4691e2', '#519de7', '#5da8ec', '#6ab3f0', '#78bef4', '#86c9f7', '#96d3fb', '#a6deff'];
  public static secondary: string[] = ['#dd543e', '#e26349', '#e77155', '#ec7e61', '#f08b6d', '#f4987a', '#f8a488', '#fcb196', '#ffbda4'];
  public static success: string[] = ['#30aa28','#44b238','#55bb46','#64c354','#73cc62','#81d46f','#8fdd7d','#9de58a','#aaee98'];
  */

  //public static primary: string[] = ['#3e86dd', '#4691e2', '#519de7', '#5da8ec', '#6ab3f0', '#78bef4', '#86c9f7', '#96d3fb', '#a6deff'];
  public static secondary: string[] = ['#e46c59'];
  public static success: string[] = ['#5dab48', '#31bab5', '#3290e7'];
  public static generalColors: string[] = ['#4dc9f6','#f67019','#f53794','#537bc4','#acc236','#166a8f','#00a950','#58595b','#8549ba'];

  /*
  public static getPrimaryColor(index: number, total: number): string {
    return MyPalette.getColor(MyPalette.primary, index, total);
  }
  */

  public static getGeneralColors(index: number, total: number): string {
    return MyPalette.getColor(MyPalette.generalColors, index, total);
  }

  public static getSecondaryColor(index: number, total: number): string {
    return MyPalette.getColor(MyPalette.secondary, index, total);
  }

  public static getSuccessColor(index: number, total: number): string {
    return MyPalette.getColor(MyPalette.success, index, total);
  }

  private static getColor(palette: string[], index: number, total: number) {
    if (total >= palette.length) {
      return palette[index % palette.length];
    } else {
      return palette[index * Math.trunc((palette.length + 1) / total)];
    }
  }
}