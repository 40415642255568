import * as React from 'react';
import { Component } from 'react';
import { Avatar, Paper, WithTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Training, Language } from '../api/Api';
import withTheme from '@material-ui/core/styles/withTheme';
import LockIcon from '@material-ui/icons/LockRounded';
import Button from '@material-ui/core/Button';
import { withTranslation, WithTranslation } from 'react-i18next';

interface TrainingCardProps extends WithTranslation, WithTheme{
  training: Training;
  lang: Language;
  onClick: () => void;
}

interface TrainingCardState {
}

class TrainingCard extends Component<TrainingCardProps, TrainingCardState> {

  constructor(props: TrainingCardProps) {
    super(props);
    this.state = {};
  }

  render() {

    let color = (this.props.training.locked ? this.props.theme.palette.grey['400'] : this.props.theme.palette.primary.main);


    let content;
    if (this.props.training.locked) {
      content = <Avatar style={{width: '136px', height: '136px', margin: 'auto'}}>
        <LockIcon style={{fontSize: '100px'}}/>
      </Avatar>;
    } else {
      content = <div>
        <Typography align='center'>{this.props.training.description[this.props.lang]}</Typography>
        <Button
          color='primary'
          variant='contained'
          style={{
            display    : 'block',
            marginLeft : 'auto',
            marginRight: 'auto',
            marginTop  : '30px'
          }}
          onClick={() => this.props.onClick()}
        >
          {this.props.t('Actions.manage')}
        </Button>
      </div>;
    }

    return <Paper style={{
      padding  : '30px',
      color    : color,
      border   : '3px solid' + color,
      minHeight: '250px',
      maxHeight: '250px',
      marginRight: '3.5vw',
      display: "table"
    }} elevation={0} square={true}>
      <Grid container direction='column'>
        <Grid item>
          <Typography variant='h1' align='center' color={'inherit'} gutterBottom={true} style={{marginBottom: '30px'}}>{this.props.training.name[this.props.lang]}</Typography>
          <div style={{
            flexGrow    : 1,
            borderBottom: '3px solid ' + color,
            margin      : '0 10% 30px 10%',
            minHeight   : '1px',
            maxHeight   : '1px'
          }}/>
        </Grid>
        <Grid item style={{textAlign: 'center', fontSize: '150px'}}>
          {content}
        </Grid>
      </Grid>
    </Paper>;
  }

}

export default withTranslation()(withTheme(TrainingCard));