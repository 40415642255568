import * as React from 'react';
import { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { Breadcrumbs, Button, Grid, Link, Typography, WithTheme } from '@material-ui/core';
import { Language, TrainingModule } from '../api/Api';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as moment from 'moment';
import MyPalette from '../misc/MyPalette';
import TestsList from './TestsList';
import TestDetails from './TestDetails';

interface MatriculeDetailsProps extends WithTranslation, WithTheme {
  lang: Language;
  wsManager: WsConnectionManager;
  matricule: any;
  modules?: TrainingModule[];
  onClose: () => void;
}

interface MatriculeDetailsState {
  testSelected?: any;
}

export default class MatriculeDetails extends Component<MatriculeDetailsProps, MatriculeDetailsState> {

  constructor(props: MatriculeDetailsProps) {
    super(props);
    this.state = {};
  }



  render() {

    let content;
    if(this.state.testSelected == null) {
      content = <TestsList {...this.props} onTestSelected={(t)=>this.setState({testSelected: t})}/>;
    } else {
      content = <TestDetails {...this.props} testSelected={this.state.testSelected} onClose={()=>this.setState({testSelected: null})}/>;
    }

    let breadcrumbs;
    if(this.state.testSelected == null) {
      breadcrumbs = <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
        <Link onClick={()=>this.props.onClose()}><Typography variant='h6' style={{color: this.props.theme.palette.primary.main, cursor: "pointer"}}>{this.props.t('Matricules.MatriculesDetails.all')}</Typography></Link>
        <Typography variant='h6'>{this.props.t('Matricules.matricule') + ' ' + this.props.matricule._id}</Typography>
      </Breadcrumbs>
    } else {

      let moduleName = this.state.testSelected.moduleId;
      let module = this.props.modules.find(m => m.key === this.state.testSelected.moduleId);
      if (module != null) {
        moduleName = module.name[this.props.lang];
      }

      breadcrumbs = <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
        <Link onClick={()=>this.props.onClose()}><Typography variant='h6' style={{color: this.props.theme.palette.primary.main, cursor: "pointer"}}>{this.props.t('Matricules.MatriculesDetails.all')}</Typography></Link>
        <Link onClick={()=>this.setState({testSelected: null})}><Typography variant='h6' style={{color: this.props.theme.palette.primary.main, cursor: "pointer"}}>{this.props.t('Matricules.matricule') + ' ' + this.props.matricule._id}</Typography></Link>
        <Typography variant='h6'>{moduleName}</Typography>
      </Breadcrumbs>
    }

    return <Grid container direction='column'>
      <Grid item container justify={'space-between'}>
        <Grid item sm={11} container justify={'flex-start'}>
          {breadcrumbs}
        </Grid>
        <Grid item sm={1} container justify={'flex-end'} alignItems={'center'}>
          <Grid item><Button size='small' onClick={(this.state.testSelected == null ? ()=>this.props.onClose() : ()=>this.setState({testSelected: null}))} variant='contained'>{this.props.t('Actions.back')}</Button></Grid>
        </Grid>
      </Grid>
      <Grid item>
        {content}
      </Grid>
    </Grid>;
  }
}