import * as React from 'react';
import { Component } from 'react';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { AppConfig, BreadcrumbsStep, Language, Training, TrainingSession } from '../api/Api';
import { Button, Grid, WithTheme } from '@material-ui/core';
import Header from '../cmp/Header';
import Headline from '../cmp/Headline';
import withTheme from '@material-ui/core/styles/withTheme';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SessionsIcon from '@material-ui/icons/SettingsInputAntenna';
import StatisticsIcon from '@material-ui/icons/PieChart';
import TrainingStats from '../training/TrainingStats';
import TrainingSessions from '../training/TrainingSessions';
import { withTranslation, WithTranslation } from 'react-i18next';
import SessionPage from './SessionPage';

interface TrainingPageProps extends WithTranslation, WithTheme {
  wsManager: WsConnectionManager;
  lang: Language;
  training: Training;
  appConfig: AppConfig;
  onClose: () => void;
}

interface TrainingPageState {
  currentTab: 'stats' | 'sessions';
  breadcrumbSteps?: BreadcrumbsStep[];
  ongoingSession?: TrainingSession;
}

class TrainingPage extends Component<TrainingPageProps, TrainingPageState> {

  constructor(props: TrainingPageProps) {
    super(props);
    this.state = {
      currentTab: 'stats'
    };
  }

  componentDidMount(): void {
    this.setBreadcrumb();
  }

  componentDidUpdate(prevProps: Readonly<TrainingPageProps>, prevState: Readonly<TrainingPageState>, snapshot?: any): void {
    if (prevProps.lang !== this.props.lang) {
      this.setBreadcrumb();
    }
  }

  private setBreadcrumb() {
    let res = [{
      name: this.props.t('TrainingPage.trainings'), onClick: () => {
        this.props.onClose()
      }
    }, {name: this.props.training.name[this.props.lang]}];

    if (this.state.ongoingSession != null) {
      res[res.length - 1].onClick = () => {
        res.pop();
        res[res.length - 1].onClick = null;
        this.setState({ongoingSession: null});
      };
      res.push({name: this.state.ongoingSession.key});
    }
    this.setState({breadcrumbSteps: res});
  }

  private joinSession(session: TrainingSession) {
    let breadcrumb = this.state.breadcrumbSteps;
    breadcrumb[breadcrumb.length - 1].onClick = () => {
      breadcrumb.pop();
      breadcrumb[breadcrumb.length - 1].onClick = null;
      this.setState({ongoingSession: null});
    };
    breadcrumb.push({name: session.key});
    this.setState({ongoingSession: session});
  }

  private tabSelected(tabName: 'stats' | 'sessions') {
    this.setState({currentTab: tabName});
  }

  render() {

    let tabComponent;
    switch (this.state.currentTab) {
      case 'stats': {
        tabComponent = <TrainingStats {...this.props}/>
      }
        break;
      case 'sessions': {
        if (this.state.ongoingSession != null) {
          tabComponent = <SessionPage session={this.state.ongoingSession} {...this.props} onSessionTerminated={() => {
            this.setState({currentTab: 'stats', ongoingSession: null});
          }}/>
        } else {
          tabComponent = <TrainingSessions onJoinSession={(session) => this.joinSession(session)} {...this.props}/>
        }
      }
        break;
    }

    let tabs: any;
    if(this.props.appConfig.systemModules.length > 1) {
      tabs = [];
      this.props.appConfig.systemModules.forEach(moduleName => {
        switch (moduleName) {
          case 'stats': {
            tabs.push(<Tab key='stats' label={this.props.t('TrainingPage.statistics')} icon={<StatisticsIcon style={{
              width : '32px',
              height: '32px'
            }}/>} value='stats'/>);
          } break;
          case 'sessions': {
            tabs.push(<Tab key='sessions' label={this.props.t('TrainingPage.sessions')} icon={<SessionsIcon style={{
              width : '32px',
              height: '32px'
            }}/>} value='sessions'/>);
          } break;
          default: {
            console.warn("Unknown moduleName: " + moduleName);
          }
        }

      });

      tabs = <Tabs
        key='tabs'
        value={this.state.currentTab}
        onChange={(e, v) => this.tabSelected(v)}
        variant="standard"
        scrollButtons="on"
        centered={true}
        textColor="primary"
        TabIndicatorProps={{
          hidden: true,
        }}
        style={{
          display: 'flex',
          marginTop: '5vh',
          marginBottom: '3vh',
          minWidth: 'fit-content'
        }}
      >
        {tabs}
      </Tabs>
    }

    return <Grid container direction='column'>
      <Grid item style={{width: '90vw', margin: 'auto'}}>
        <Header steps={this.state.breadcrumbSteps}/>
      </Grid>
      <Grid item container direction='column' style={{width: '90vw', margin: 'auto'}}>
        <Grid item style={{display: 'flex'}}>
          {this.state.ongoingSession == null ?
            <Headline key='headline' text={this.props.training.name[this.props.lang]}/>
            : <Headline text={this.props.t('TrainingPage.ongoingSessionHeadline', {
              sessionName : this.state.ongoingSession.key,
              trainingName: this.props.training.name[this.props.lang]
            })}/>
          }
          {tabs}
        </Grid>
      </Grid>
      <Grid item container direction='column'>
        <div style={{width: '100vw', backgroundColor: this.props.theme.palette.grey['100'], paddingBottom: '50px'}}>
          <div style={{width: '90vw', margin: 'auto'}}>
            {tabComponent}
          </div>
        </div>
      </Grid>
    </Grid>;
  }
}

export default withTranslation()(withTheme(TrainingPage));

