import * as React from 'react';
import { Component, CSSProperties } from 'react';
import { ApiRequest, Language, Test, Training, TrainingModule } from '../api/Api';
import { Button, CircularProgress, Grid, TablePagination, Theme, Typography, WithTheme } from '@material-ui/core';
import * as moment from 'moment';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import { WithTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';

interface MatriculesListProps extends WithTranslation, WithTheme {
  lang: Language;
  wsManager: WsConnectionManager;
  onMatriculeSelected: (selection: any) => void;
  modules?: TrainingModule[];
  tests?: Test[];
  training: Training;
  from: moment.Moment;
  to: moment.Moment;
}

interface MatriculesListState {
  bulkResults: any[];
  ready: boolean;
  currentFilter?: string;
  currentPage: number;
  currentRowsPerPage: number;
  totalRecords: number;
  hoveredLine?: number;
}

export default class MatriculesList extends Component<MatriculesListProps, MatriculesListState> {

  private searchText: HTMLInputElement;

  constructor(props: MatriculesListProps) {
    super(props);
    this.state = {
      bulkResults       : [],
      ready             : false,
      currentPage       : 0,
      currentRowsPerPage: 10,
      totalRecords      : 0
    };
  }

  componentDidMount(): void {
    this.getBulkResults(results => {
      this.getBulkResultsCount(count => {
        this.setState({
          bulkResults : results,
          totalRecords: count.size,
          ready       : true
        });
      });
    });
  }

  private getBulkResults(cb: (res: any[]) => void) {
    let request = {
      action    : 'db-bulk-results-get',
      parameters: {
        skip : this.state.currentPage * this.state.currentRowsPerPage,
        limit: this.state.currentRowsPerPage
      }
    } as ApiRequest;
    if (this.searchText.value.length != 0) {
      request.parameters.search = this.searchText.value;
    }
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve bulkResults', response);
        cb(null);
      } else {
        cb((response.result as any[]))
      }
    });
  }

  private getBulkResultsCount(cb: (res: any) => void) {
    let request = {action: 'db-bulk-results-count'} as ApiRequest;
    if (this.searchText.value.length != 0) {
      request.parameters = {search: this.searchText.value};
    }
    this.props.wsManager.request(request, response => {
      if (response['status'] !== 'ok') {
        console.error('Could not retrieve bulkResultsCount', response);
        cb(null);
      } else {
        cb((response.result as any))
      }
    });
  }


  private filterResultsByMatricule(searchString: string) {
    this.getBulkResults(results => {
      this.getBulkResultsCount(count => {
        this.setState({
          currentPage : 0,
          bulkResults : results,
          totalRecords: (count == null ? 0 : count.size)
        });
      });
    });
  }

  private onPageChange(pageNumber: number) {
    this.setState({currentPage: pageNumber}, () => {
      this.getBulkResults(res => {
        this.setState({
          bulkResults: res
        });
      });
    })
  }

  private onRowsPerPageChange(rowsPerPage: number) {
    this.setState({
      currentPage       : 0,
      currentRowsPerPage: rowsPerPage
    }, () => {
      this.getBulkResults(res => {
        this.setState({
          bulkResults: res
        });
      })
    })
  }

  render() {

    /*
    let visibleIndexMin = this.state.currentPage * this.state.currentRowsPerPage;
    let visibleIndexMax = visibleIndexMin + this.state.currentRowsPerPage;
    */

    let content;
    if (this.state.ready == false) {
      content = <CircularProgress value={-1}/>;
    } else {
      content = <table style={{width: '100%', borderSpacing: '0 20px'}}>
        <thead>
        <tr>
          <td><Typography variant={'body1'}>{this.props.t('Matricules.matricule')}</Typography></td>
          <td><Typography variant={'body1'}>{this.props.t('Matricules.lastResult')}</Typography></td>
          <td><Typography variant={'body1'}>{this.props.t('Matricules.scenario')}</Typography></td>
          <td><Typography variant={'body1'}>{this.props.t('Matricules.result')}</Typography></td>
        </tr>
        </thead>
        <tbody>
        {this.state.bulkResults.map((result, idx) => {

          let mostRecentRecord = result.records[0];

          let moduleName = mostRecentRecord.moduleId;
          let module = this.props.modules.find(m => m.key === mostRecentRecord.moduleId);
          if (module != null) {
            moduleName = module.name[this.props.lang];
          }

          let style : CSSProperties = {
            outline      : '2px solid',
            outlineColor : this.props.theme.palette.primary.main,
            cursor       : 'pointer'
          };

            if(this.state.hoveredLine != null && this.state.hoveredLine === result._id) {
              style.background = this.props.theme.palette.primary.main;
              style.color = 'white';
            }

          return <tr key={result._id} style={style}
                     onClick={() => this.props.onMatriculeSelected(result)}
                     onMouseEnter={event => this.setState({hoveredLine: result._id})}
                     onMouseLeave={event => this.setState({hoveredLine: null})}
          >
            <td style={{padding: '10px'}}>
              <Typography variant={'body1'} style={{color: 'inherit'}}>{result._id}</Typography></td>
            <td>
              <Typography variant={'body1'} style={{color: 'inherit'}}>{moment(mostRecentRecord.lastModified).format('DD/MM/YYYY')}</Typography>
            </td>
            <td><Typography variant={'body1'} style={{color: 'inherit'}}>{moduleName}</Typography></td>
            <td>
              <Typography variant={'body1'} style={{color: 'inherit'}}>{(mostRecentRecord.formateurValidation ? this.props.t('Charts.passed') : this.props.t('Charts.failed'))}</Typography>
            </td>
            {/*
            <td style={{paddingRight: '20px', whiteSpace: 'nowrap'}}>
              <Button size={'small'} variant={'contained'} onClick={()=>this.props.onMatriculeSelected(result)} style={{borderRadius: 0}}>Voir tous les résultats</Button></td>
              */}
          </tr>;
        })}
        </tbody>
      </table>;
    }

    return <Grid container direction='column' spacing={4}>
      <Grid item container justify={'space-between'}>
        <Grid item sm={4} container justify={'flex-start'} alignItems={'center'}>
          <Grid item><Typography variant={'body1'}>{this.props.t('Matricules.MatriculesList.resultsPerMatricule')}</Typography></Grid>
        </Grid>
        <Grid item sm={4} container justify={'center'}>
          <Grid item>
            <TablePagination
              labelRowsPerPage={this.props.t('Matricules.MatriculesList.rowsPerPage')}
              component='div'
              page={this.state.currentPage}
              rowsPerPage={this.state.currentRowsPerPage}
              count={this.state.totalRecords}
              onChangePage={(event, pageNumber) => this.onPageChange(pageNumber)}
              onChangeRowsPerPage={(event) => this.onRowsPerPageChange(Number(event.target.value))}
            />
          </Grid>
        </Grid>
        <Grid item sm={4} container justify={'flex-end'} alignItems={'center'}>
          <Grid item><SearchIcon/></Grid>
          <Grid item><input type='text' placeholder={this.props.t('Matricules.matricule')} ref={(me) => this.searchText = me} onChange={event => this.filterResultsByMatricule(event.currentTarget.value)}/></Grid>
        </Grid>
      </Grid>
      <Grid item>{content}</Grid>
    </Grid>;
  }
}