import { ApiRequest, ApiResponse } from '../api/Api';
import { WsConnectionManager } from './WsConnectionManager';


export default class HeadsetCommands {

  public static resetOne(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, serialNumber: string) {
    let request: Partial<ApiRequest> = {
      action: 'headset-reset',
      parameters: {
        serialNumber: serialNumber
      }
    };
    wsManager.request(request, callback);
  }

  public static pauseOne(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, serialNumber: string) {
    let request: Partial<ApiRequest> = {
      action: 'headset-pause',
      parameters: {
        serialNumber: serialNumber
      }
    };
    wsManager.request(request, callback);
  }

  public static resumeOne(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, serialNumber: string) {
    let request: Partial<ApiRequest> = {
      action: 'headset-resume',
      parameters: {
        serialNumber: serialNumber
      }
    };
    wsManager.request(request, callback);
  }

  public static enrollOne(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, serialNumber: string, trainingKey: string, trainingSessionKey: string) {
    let request: Partial<ApiRequest> = {
      action: 'headset-enroll',
      parameters: {
        serialNumber: serialNumber,
        trainingKey: trainingKey,
        trainingSessionKey: trainingSessionKey
      }
    };
    wsManager.request(request,callback);
  }

  public static dismissOne(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, serialNumber: string, trainingSessionKey: string) {
    let request: Partial<ApiRequest> = {
      action: 'headset-dismiss',
      parameters: {
        serialNumber: serialNumber,
        trainingSessionKey: trainingSessionKey
      }
    };
    wsManager.request(request, callback);
  }

  public static enrollAll(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, serialNumbers: string[], trainingKey: string, trainingSessionKey: string) {
    let request: Partial<ApiRequest> = {
      action: 'session-enroll',
      parameters: {
        serialNumbers: serialNumbers,
        trainingKey: trainingKey,
        trainingSessionKey: trainingSessionKey
      }
    };
    wsManager.request(request,callback);
  }

  public static dismissAll(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, trainingSessionKey: string) {
    let request: Partial<ApiRequest> = {
      action: 'session-dismiss',
      parameters: {
        trainingSessionKey: trainingSessionKey
      }
    };
    wsManager.request(request, callback);
  }

  public static pauseAll(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, trainingSessionKey: string) {
    let request: Partial<ApiRequest> = {
      action: 'session-pause',
      parameters: {
        trainingSessionKey: trainingSessionKey
      }
    };
    wsManager.request(request,callback);
  }

  public static resumeAll(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, trainingSessionKey: string) {
    let request: Partial<ApiRequest> = {
      action: 'session-resume',
      parameters: {
        trainingSessionKey: trainingSessionKey
      }
    };
    wsManager.request(request,callback);
  }

  public static resetAll(wsManager: WsConnectionManager, callback: (response:ApiResponse)=>void, trainingSessionKey: string) {
    let request: Partial<ApiRequest> = {
      action: 'session-reset',
      parameters: {
        trainingSessionKey: trainingSessionKey
      }
    };
    wsManager.request(request,callback);
  }




}