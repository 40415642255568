import * as React from 'react';
import { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { Language, OrganisationalUnit, OrganisationalUnitType, Test, TestResult, Training, TrainingModule, TrainingSession } from '../api/Api';
import { Grid, Typography, WithTheme } from '@material-ui/core';
import { WsConnectionManager } from '../misc/WsConnectionManager';
import ModuleSuccessGauge from '../chart/ModuleSuccessGauge';
import ModulesPerSessionChart from '../chart/ModulesPerSessionChart';
import MyPalette from '../misc/MyPalette';
import ModulePerOrgUnitOverTime from '../chart/ModulePerOrgUnitOverTime';
import SuccessPerQuestionChart from '../chart/SuccessPerQuestionChart';
import SuccessPerQuestionRadar from '../chart/SuccessPerQuestionRadar';

interface ModulesStatsProps extends WithTranslation, WithTheme {
  training: Training;
  wsManager: WsConnectionManager;
  lang: Language;
  orgUnitTypes?: OrganisationalUnitType[];
  orgUnits?: OrganisationalUnit[];
  modules?: TrainingModule[];
  tests?: Test[];
  results?: TestResult[];
  sessions?: TrainingSession[];
}

interface ModulesStatsState {
}

export default class ModulesStats extends Component<ModulesStatsProps, ModulesStatsState> {

  constructor(props: ModulesStatsProps) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(): void {
  }


  render() {

    if(this.props.modules == null) {
      return <Typography key='noData'>No data.</Typography>;
    }


    return <Grid container direction='column' style={{padding: '20px'}}  spacing={6}>
      <Grid item container justify='center' spacing={5}>
        {this.props.modules.map((module, idx) => {
          return <Grid item xs key={module.key}>
            <SuccessPerQuestionRadar module={module} {...this.props} colors={{success: MyPalette.getSuccessColor(idx, this.props.modules.length), failure: MyPalette.getSecondaryColor(idx, this.props.modules.length)}}/>
          </Grid>;
        })}
      </Grid>
      {this.props.modules.map((module, idx) => {
        return <Grid item key={module.key}>
          <SuccessPerQuestionChart module={module} {...this.props} colors={{success: MyPalette.getSuccessColor(idx, this.props.modules.length), failure: MyPalette.getSecondaryColor(idx, this.props.modules.length)}}/>
        </Grid>;
      })}
    </Grid>;
  }
}